import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled } from '@ant-design/icons';

import './ReportedMedia.scss';

const ReportedMedia: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="ReportedMedia">
      <CheckCircleFilled className="ReportedMedia__icon" />
      <div className="ReportedMedia__title">{t('faq-report-thank-you')}</div>
    </div>
  );
};

export default ReportedMedia;
