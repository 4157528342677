import { call, type Effect, type ForkEffect, select, takeEvery, put } from 'redux-saga/effects';
import fetchService from '../../services/fetchService';
import { getCaseDetail } from '../caseDetail/caseDetailSlice';
import { type CasePublicDto, type FaqWithTranslationsDto } from '../../types/dtos';
import localizationService from '../../services/localizationService';
import { helpActions } from './helpSlice';

function* watchOpen(): Generator<Effect, void, any> {
  const caseDetail: CasePublicDto = yield select(getCaseDetail);
  try {
    const reportMediaResponse: FaqWithTranslationsDto = yield call(fetchService.getFaq, caseDetail.dpocCaseId);

    yield call(localizationService.addTranslations, reportMediaResponse.translations);

    yield put(
      helpActions.openFinished({ faqList: reportMediaResponse.faqList, dpocEmail: reportMediaResponse.dpocEmail })
    );
  } catch {
    yield put(helpActions.openFailed());
  }
}

function* watchReportMedia(): Generator<Effect, void, any> {
  const caseDetail: CasePublicDto = yield select(getCaseDetail);
  try {
    yield call(fetchService.reportMedia, caseDetail.dpocCaseId);
    yield put(helpActions.reportMediaFinished());
  } catch {
    yield put(helpActions.reportMediaFailed());
  }
}

export function* watchHelpSagas(): Generator<ForkEffect, void> {
  yield takeEvery(helpActions.open, watchOpen);
  yield takeEvery(helpActions.reportMedia, watchReportMedia);
}

const helpSagas = watchHelpSagas;

export default helpSagas;
