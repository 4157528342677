import React, { type FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import StepHeader from '../Upload/StepHeader';
import { type CasePublicDto } from '../../types/dtos';
import { useGetStepMedia } from '../../redux/upload/uploadHooks';
import UploadButton from '../Upload/UploadButton';
import UploadedMedia from '../Upload/UploadedMedia';
import windowService from '../../services/windowService';
import { useLanguage } from '../../redux/language/languageHooks';
import { MediaTypes } from '../../types/constants';

import './ExtraStep.scss';

interface ExtraStepProps {
  caseDetail: CasePublicDto;
}

const ExtraStep: FC<ExtraStepProps> = ({ caseDetail }) => {
  const { t } = useTranslation();
  const { selectedLocale } = useLanguage();

  const totalIndices = caseDetail?.submission.stepInstructions[selectedLocale.localeCode].length ?? 0;

  const stepMedia = useGetStepMedia(totalIndices);
  const hasMedia = !!stepMedia.length;
  const description = hasMedia ? t('Submit_description') : undefined;

  return (
    <div className="ExtraStep">
      <StepHeader
        stepNumber={totalIndices}
        title={
          <Trans
            i18nKey="Submit_header"
            components={{
              b: <strong />,
            }}
          />
        }
        totalIndices={totalIndices}
        description={description}
      />
      {!hasMedia && (
        <div className="ExtraStep__limit">
          <Trans i18nKey="Submit_limit" />
        </div>
      )}
      {!hasMedia && windowService.getIsDesktopScreen() && (
        <div className="ExtraStep__description">{t('Submit_description')}</div>
      )}
      <div className="ExtraStep__upload">
        <UploadButton mediaType={MediaTypes.IMAGE} stepNumber={totalIndices} />
      </div>
      {!hasMedia && !windowService.getIsDesktopScreen() && (
        <div className="ExtraStep__description">{t('Submit_description')}</div>
      )}
      <UploadedMedia mediaType={MediaTypes.IMAGE} stepNumber={totalIndices} stepMedia={stepMedia} isMandatory={false} />
    </div>
  );
};

export default ExtraStep;
