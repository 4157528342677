import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from '../hooks';
import { getIsSubmitting, getMedia, uploadActions } from './uploadSlice';
import { type MediaData } from '../../types/app';
import { type MediaTypes } from '../../types/constants';

type SubmitAction = () => void;
type DeleteAction = (stepNumber: number, imageIndex: number, mediaType: MediaTypes, s3Key: string) => void;
type GetStepMediaAction = (stepNumber: number) => MediaData[];
type GetAllMediaAction = () => MediaData[];

export const useGetStepMedia: GetStepMediaAction = (stepNumber) => {
  const media = useAppSelector(getMedia);
  return media[stepNumber] || [];
};

export const useGetAllMedia: GetAllMediaAction = () => {
  const media = useAppSelector(getMedia);
  return Array.prototype.concat.apply([], Object.values(media));
};

export const useUploadFile: (stepNumber: number, mediaType: MediaTypes) => (file: File) => void = (
  stepNumber,
  mediaType
) => {
  const dispatch = useAppDispatch();

  const uploadFile: (file: File) => void = useCallback(
    (file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        const fileContent = e.target?.result as string;

        dispatch(
          uploadActions.uploadFile({
            content: fileContent,
            file,
            mediaType,
            stepNumber,
          })
        );
      };
    },
    [dispatch, mediaType, stepNumber]
  );

  return uploadFile;
};

export const useSubmit: () => [SubmitAction, boolean] = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSubmitting = useAppSelector(getIsSubmitting);

  const submit = useCallback(() => {
    dispatch(uploadActions.submit(navigate));
  }, [dispatch, navigate]);

  return [submit, isSubmitting];
};

export const useDeleteImage: () => DeleteAction = () => {
  const dispatch = useAppDispatch();

  const deleteFile: DeleteAction = (stepNumber, mediaIndex, mediaType, s3Key) => {
    dispatch(
      uploadActions.deleteMedia({
        mediaIndex,
        stepNumber,
        mediaType,
        s3Key,
      })
    );
  };

  return deleteFile;
};
