import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '../store';
import { type CasePublicDto } from '../../types/dtos';

import { type NavigateFunction } from 'react-router/dist/lib/hooks';
import { uploadActions } from '../upload/uploadSlice';

export interface CaseDetailSlice {
  value?: CasePublicDto;
  submissionStarting: boolean;
  submissionStarted: boolean;
  submissionFinished: boolean;
  fetching: boolean;
  termsAgreed: boolean;
}

export const initialState: CaseDetailSlice = {
  value: undefined,
  fetching: false,
  submissionStarting: false,
  submissionStarted: false,
  submissionFinished: false,
  termsAgreed: false,
};

export const getCaseDetail = (state: RootState): CasePublicDto | undefined => state.caseDetail.value;
export const getIsFetching = (state: RootState): boolean => state.caseDetail.fetching;
export const getIsSubmissionStarting = (state: RootState): boolean => state.caseDetail.submissionStarting;
export const getIsSubmissionStarted = (state: RootState): boolean => state.caseDetail.submissionStarted;
export const getIsSubmissionFinished = (state: RootState): boolean => state.caseDetail.submissionFinished;
export const getTermsAgreed = (state: RootState): boolean => state.caseDetail.termsAgreed;

export interface FetchCaseDetailPayload {
  dpocCaseId: string;
  navigate: NavigateFunction;
}

export interface StartGuidedInstructionsPayload {
  termsAgreed: boolean;
  navigate: NavigateFunction;
}

export const caseDetailSlice = createSlice({
  name: 'caseDetail',
  initialState,
  reducers: {
    fetchDetail: (state, _action: PayloadAction<FetchCaseDetailPayload>) => {
      state.fetching = true;
      state.submissionFinished = false;
      state.submissionStarted = false;
      state.termsAgreed = false;
    },
    fetchDetailFinished: (state, action: PayloadAction<CasePublicDto>) => {
      state.value = action.payload;
      state.fetching = false;
    },
    fetchDetailFailed: (state) => {
      state.fetching = false;
    },
    startSubmission: (state, action: PayloadAction<StartGuidedInstructionsPayload>) => {
      state.termsAgreed = action.payload.termsAgreed;
      state.submissionStarting = true;
    },
    startSubmissionFinished: (state) => {
      state.submissionStarting = false;
      state.submissionStarted = true;
    },
    startSubmissionFailed: (state) => {
      state.submissionStarting = false;
    },
    reset: (state) => {
      Object.keys(initialState).forEach((key) => {
        // @ts-expect-error Reset all properties to default state.
        state[key] = initialState[key];
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadActions.submitFinished, (state) => {
      state.submissionFinished = true;
    });
  },
});

export const { actions: caseDetailActions, reducer: caseDetailReducer } = caseDetailSlice;
