import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type CasePublicDto, type StepInstructionPublicDto } from '../../types/dtos';

export const initialState = {};

export interface LogAnalyticsPayload {
  eventType: string;
  screenName: string;
  dpocCaseId?: CasePublicDto['dpocCaseId'];
  stepInstruction?: StepInstructionPublicDto;
  value?: string;
}

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    log: (_state, _action: PayloadAction<LogAnalyticsPayload>) => {},
    logFinished: (_state) => {},
    logFailed: (_state) => {},
  },
});

export const { actions: analyticsActions, reducer: analyticsReducer } = analyticsSlice;
