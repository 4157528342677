import React, { type FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './Policies.scss';
import PolicyCard from './PolicyCard';

// eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
const PrivacyDescriptionTrans: FC = () => <Trans i18nKey="Policies_PrivacyDescription" components={{ a: <a /> }} />;

const Policies: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="Policies">
      <div className="Policies__header">{t('Policies_header')}</div>
      <div className="Policies__cards">
        <PolicyCard description={<PrivacyDescriptionTrans />} header={t('Policies_PrivacyHeader')} />
        <PolicyCard header={t('Policies_GDPRHeader')} description={t('Policies_GDPRDescription')} />
        <PolicyCard header={t('Policies_ContentHeader')} description={t('Policies_ContentDescription')} />
      </div>
    </div>
  );
};

export default Policies;
