import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  gatFaqList,
  getIsFaqFetching,
  getIsHelpOpen,
  getIsMediaReported,
  getIsReportingMedia,
  helpActions,
} from './helpSlice';
import { useAnalytics } from '../analytics/analyticsHooks';
import { ANALYTICS_EVENT_TYPES } from '../../types/constants';
import { type FaqDto, type FaqPageDto } from '../../types/dtos';
import { useCaseDetail } from '../caseDetail/caseDetailHooks';

export const useHelpButton: () => {
  faqList?: Array<FaqDto | FaqPageDto>;
  isOpen: boolean;
  fetching: boolean;
  helpAllowed: boolean;
  open: () => void;
  close: () => void;
} = () => {
  const dispatch = useAppDispatch();
  const [caseDetail] = useCaseDetail();

  const helpAllowed = !!caseDetail?.dpocCaseId;

  const faqList = useAppSelector(gatFaqList);
  const isOpen = useAppSelector(getIsHelpOpen);
  const fetching = useAppSelector(getIsFaqFetching);

  const { log } = useAnalytics();

  const open = useCallback(() => {
    log(ANALYTICS_EVENT_TYPES.HelpDialog, 'open');
    dispatch(helpActions.open());
  }, [dispatch, log]);

  const close = useCallback(() => {
    log(ANALYTICS_EVENT_TYPES.HelpDialog, 'close');
    dispatch(helpActions.close());
  }, [dispatch, log]);

  return { faqList, isOpen, fetching, helpAllowed, open, close };
};

export const useHelpWizard: (isOpen: boolean) => {
  currentStep: 'INTRO' | 'FAQ_LIST' | 'FAQ_DETAIL' | 'MEDIA_REPORTED';
  selectedFaqItem: FaqPageDto | undefined;
  selectFaqItem: (faqItem: FaqPageDto) => void;
  isReportingMedia: boolean;
  back?: () => void;
  showFaq: () => void;
  reportMedia: () => void;
} = (isOpen) => {
  const dispatch = useAppDispatch();
  const { log } = useAnalytics();

  const [selectedFaqItem, setSelectedFaqItem] = useState<FaqPageDto | undefined>();

  const isReportingMedia = useAppSelector(getIsReportingMedia);
  const isMediaReported = useAppSelector(getIsMediaReported);

  const [stepsStack, setStepsStack] = useState<Array<'INTRO' | 'FAQ_LIST' | 'FAQ_DETAIL' | 'MEDIA_REPORTED'>>([
    'INTRO',
  ]);

  const targetStep = stepsStack[stepsStack.length - 1];
  const currentStep = targetStep === 'MEDIA_REPORTED' && !isMediaReported ? 'FAQ_DETAIL' : targetStep;

  useEffect(() => {
    // Reset state on dialog reopen.
    if (isOpen) {
      setStepsStack(['INTRO']);
    }
  }, [isOpen]);

  const back = useCallback(() => {
    log(ANALYTICS_EVENT_TYPES.HelpDialogStep, JSON.stringify({ step: stepsStack[stepsStack.length - 2], back: true }));
    setStepsStack(stepsStack.slice(0, stepsStack.length - 1));
  }, [log, stepsStack]);

  const showFaq = (): void => {
    log(ANALYTICS_EVENT_TYPES.HelpDialogStep, JSON.stringify({ step: 'FAQ_LIST' }));
    setStepsStack([...stepsStack, 'FAQ_LIST']);
  };

  const selectFaqItem = (faqItem: FaqPageDto): void => {
    setSelectedFaqItem(faqItem);
    log(ANALYTICS_EVENT_TYPES.HelpDialogStep, JSON.stringify({ step: 'FAQ_DETAIL', titleKey: faqItem.titleKey }));
    setStepsStack([...stepsStack, 'FAQ_DETAIL']);
  };

  const reportMedia = (): void => {
    dispatch(helpActions.reportMedia());
    log(ANALYTICS_EVENT_TYPES.HelpDialogStep, JSON.stringify({ step: 'MEDIA_REPORTED' }));
    setStepsStack([...stepsStack, 'MEDIA_REPORTED']);
  };

  return {
    currentStep,
    isReportingMedia,
    selectedFaqItem,
    selectFaqItem,
    back: currentStep !== 'MEDIA_REPORTED' && stepsStack.length > 1 ? back : undefined,
    showFaq,
    reportMedia,
  };
};

export const useHelpfulFaq: (faqItem: FaqPageDto) => {
  enabled: boolean;
  sayYes: () => void;
  sayNo: () => void;
} = (faqItem) => {
  const [enabled, setEnabled] = useState(true);
  const { log } = useAnalytics();

  const answer = useCallback(
    (answer: string) => () => {
      log(ANALYTICS_EVENT_TYPES.FAQHelpful, JSON.stringify({ titleKey: faqItem.titleKey, answer }));
      setEnabled(false);
    },
    [faqItem.titleKey, log]
  );

  return { enabled, sayYes: answer('Yes'), sayNo: answer('No') };
};
