import React, { type FC } from 'react';
import { Spin } from 'antd';
import { type SpinSize } from 'antd/es/spin';
import classNames from 'classnames';
import './CenteredSpin.scss';

interface CenteredSpinProps {
  size?: SpinSize;
}

const CenteredSpin: FC<CenteredSpinProps> = ({ size = 'large' }) => (
  <div
    className={classNames('CenteredSpin', {
      'CenteredSpin--default': size === 'default',
      'CenteredSpin--small': size === 'small',
    })}
  >
    <Spin size={size}>&nbsp;</Spin>
  </div>
);

export default CenteredSpin;
