/* eslint-disable no-template-curly-in-string */

// The configuration is stored in '/public/configuration.js' and modified during deployment depending on environment.
// The configuration is imported directly using <script> tag in html page.
// Purpose of this file is to allow easier access to the configuration for the rest of the application.

interface T_PQC_APPLICATION_CONFIGURATION {
  API_URL?: string;
  X_Merck_APIKey?: string;
  Display_Application_Version?: string;
  App_Error_Email?: string;
}

// Here must be the same placeholders as in default version(not modified by deployment scripts) of '/public/configuration.js' file.
const placeHolders: T_PQC_APPLICATION_CONFIGURATION = {
  API_URL: '${backend_api_url}',
  X_Merck_APIKey: '${x_merck_api_key}',
  Display_Application_Version: '${application_version}',
  App_Error_Email: '${app_error_email}',
};

// From Configuration file '/public/configuration.js' modified during deploy phase and imported in HTML HEAD <SCRIPT> tag
// or from jest.config.js file in case of tests.
declare const PQC_APPLICATION_CONFIGURATION: T_PQC_APPLICATION_CONFIGURATION;

// Values from /public/configuration.js where default 'placeholder' values are converted to undefined.
export const APPLICATION_CONFIGURATION =
  typeof PQC_APPLICATION_CONFIGURATION !== 'undefined' // is not defined in JEST tests.
    ? Object.keys(PQC_APPLICATION_CONFIGURATION)
        .map((key) => key as keyof T_PQC_APPLICATION_CONFIGURATION)
        .reduce<T_PQC_APPLICATION_CONFIGURATION>((acc, configurationKey) => {
          acc[configurationKey] =
            PQC_APPLICATION_CONFIGURATION[configurationKey] === placeHolders[configurationKey]
              ? undefined
              : PQC_APPLICATION_CONFIGURATION[configurationKey];
          return acc;
        }, {})
    : {};
