import { analyticsReducer } from './analytics/analyticsSlice';
import { countryReducer } from './country/countrySlice';
import { countrySelectionReducer } from './countrySelection/countrySelectionSlice';
import { caseDetailReducer } from './caseDetail/caseDetailSlice';
import { helpReducer } from './help/helpSlice';
import { languageReducer } from './language/languageSlice';
import { mediaCacheReducer } from './mediaCache/mediaCacheSlice';
import { uploadReducer } from './upload/uploadSlice';

const rootReducer = {
  analytics: analyticsReducer,
  country: countryReducer,
  countrySelection: countrySelectionReducer,
  caseDetail: caseDetailReducer,
  help: helpReducer,
  language: languageReducer,
  mediaCache: mediaCacheReducer,
  upload: uploadReducer,
};

export default rootReducer;
