import React, { type FC } from 'react';
import classNames from 'classnames';

import './LoadingMediaElement.scss';
import { LoadingImage, LoadingVideo } from '../index';
import { type MediaLink } from '../../types/app';

interface LoadingMediaElementProps {
  className?: string;
  mediaLink: MediaLink;
  isVideo?: boolean;
}

const LoadingMediaElement: FC<LoadingMediaElementProps> = ({ className, mediaLink, isVideo }) => {
  return isVideo ? (
    <LoadingVideo
      className={classNames('LoadingMediaElement', className)}
      autoPlay
      loop
      playsInline
      muted
      src={mediaLink.src}
      mediaKey={mediaLink.mediaKey}
    />
  ) : (
    <LoadingImage
      className={classNames('LoadingMediaElement', className)}
      src={mediaLink.src}
      mediaKey={mediaLink.mediaKey}
      alt=""
      sizes=""
    />
  );
};

export default LoadingMediaElement;
