import React, { type FC } from 'react';
import './FooterButtons.scss';

interface FooterButtonsProps {
  children: React.ReactNode;
}

const FooterButtons: FC<FooterButtonsProps> = ({ children }) => (
  <div className="FooterButtons">
    <div className="FooterButtons__content">{children}</div>
  </div>
);

export default FooterButtons;
