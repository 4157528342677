import React, { type FC } from 'react';

import { type MediaData } from '../../types/app';
import { type MediaTypes } from '../../types/constants';
import MediaCarousel, { MediaWrapper } from '../common/MediaCarousel';
import DeletableMediaElement from './DeletableMediaElement';
import UploadedMediaSummary from './UploadedMediaSummary';
import windowService from '../../services/windowService';
import UploadedMediaSummaryHeader from './UploadedMediaSummaryHeader';

import './UploadedMedia.scss';

interface UploadedMediaProps {
  isMandatory: boolean;
  mediaType: MediaTypes;
  stepNumber: number;
  stepMedia: MediaData[];
}

const UploadedMedia: FC<UploadedMediaProps> = ({ mediaType, stepNumber, stepMedia, isMandatory }) => {
  return (
    <div className="UploadedMedia">
      {windowService.getIsCarouselScreen() ? (
        <>
          <MediaCarousel>
            {stepMedia.map((_media, mediaIndex) => (
              <MediaWrapper key={mediaIndex}>
                <DeletableMediaElement stepMedia={stepMedia} mediaIndex={mediaIndex} stepNumber={stepNumber} />
              </MediaWrapper>
            ))}
          </MediaCarousel>
          <UploadedMediaSummary stepMedia={stepMedia} mediaType={mediaType} isMandatory={isMandatory} />
        </>
      ) : (
        <div className="UploadedMedia__list">
          <UploadedMediaSummaryHeader mediaType={mediaType} isMandatory={isMandatory} stepMedia={stepMedia} large />
          <div className="UploadedMedia__list__media">
            {stepMedia.map((media, mediaIndex) => (
              <DeletableMediaElement
                key={mediaIndex}
                stepMedia={stepMedia}
                mediaIndex={mediaIndex}
                stepNumber={stepNumber}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadedMedia;
