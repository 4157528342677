import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '../store';
import { type CountryLocaleDto, type LanguageSelectionTranslationsDto, type LocaleDto } from '../../types/dtos';
import { type NavigateFunction } from 'react-router/dist/lib/hooks';

export interface CountrySelectionSlice {
  countries?: CountryLocaleDto[];
  languageSelectionTranslations?: LanguageSelectionTranslationsDto;
  fetching: boolean;
  error: boolean;
}

export const initialState: CountrySelectionSlice = {
  countries: undefined,
  languageSelectionTranslations: undefined,
  fetching: false,
  error: false,
};

export const getCountries = (state: RootState): CountryLocaleDto[] | undefined => state.countrySelection.countries;
export const getLanguageSelectionTranslations = (state: RootState): LanguageSelectionTranslationsDto | undefined =>
  state.countrySelection.languageSelectionTranslations;
export const getCountriesAndTranslationsFetching = (state: RootState): boolean => state.countrySelection.fetching;
export const getCountriesAndTranslationsFetchingError = (state: RootState): boolean => state.countrySelection.error;

export interface FetchCountriesAndTranslationsResultPayload {
  countries?: CountryLocaleDto[];
  languageSelectionTranslations?: LanguageSelectionTranslationsDto;
}

export interface FetchCountriesPayload {
  navigate: NavigateFunction;
}

export interface SelectLanguageAndLocalePayload {
  country: CountryLocaleDto;
  localeCode: LocaleDto['localeCode'];
  navigate: NavigateFunction;
}

export const countrySelectionSlice = createSlice({
  name: 'countrySelection',
  initialState,
  reducers: {
    fetchCountriesAndTranslations: (state, _action: PayloadAction<FetchCountriesPayload>) => {
      state.fetching = true;
    },
    fetchCountriesAndTranslationsFinished: (
      state,
      action: PayloadAction<FetchCountriesAndTranslationsResultPayload>
    ) => {
      state.countries = action.payload.countries;
      state.languageSelectionTranslations = action.payload.languageSelectionTranslations;
      state.fetching = false;
      state.error = false;
    },
    fetchCountriesAndTranslationsFailed: (state) => {
      state.fetching = false;
      state.error = true;
    },
    selectLocale: (_state, _action: PayloadAction<SelectLanguageAndLocalePayload>) => {},
    selectLocaleFinished: () => {},
    selectLocaleFailed: () => {},
  },
});

export const { actions: countrySelectionActions, reducer: countrySelectionReducer } = countrySelectionSlice;
