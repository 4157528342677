import React, { type FC } from 'react';

import { useTranslation } from 'react-i18next';
import notFound from '../../images/notFound.svg';
import './CaseNotFound.scss';
import { Button } from 'antd';
import { routes } from '../../router';
import { AnalyticsNavLink, ApplicationTitle } from '../index';
import { ANALYTICS_EVENT_TYPES } from '../../types/constants';

const CaseNotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="CaseNotFound">
      <ApplicationTitle />
      <img className="CaseNotFound__image" alt="Case not found." src={notFound} />
      <div className="CaseNotFound__header">{t('CaseNotFound_header')}</div>
      <div className="CaseNotFound__description">{t('CaseNotFound_description')}</div>
      <AnalyticsNavLink eventType={ANALYTICS_EVENT_TYPES.BackToHome} to={routes.landingPage()}>
        <Button type="primary">{t('Confirmation_navigateHome')}</Button>
      </AnalyticsNavLink>
    </div>
  );
};

export default CaseNotFound;
