import React, { type FC } from 'react';

import { type Instruction } from '../../types/app';

import InstructionGroup from '../common/InstructionGroup';
import './InstructionsContent.scss';
import { useTranslation } from 'react-i18next';
import { type BestPracticeInstructionDto, type BestPracticesDto } from '../../types/dtos';

interface InstructionsContentProps {
  bestPractices: BestPracticesDto[];
}

const InstructionsContent: FC<InstructionsContentProps> = ({ bestPractices }) => {
  const { t } = useTranslation();

  const createInstructionGroup = (instructions: BestPracticeInstructionDto[]): Instruction[] =>
    instructions.map((instruction) => ({
      text: instruction.text,
      mediaLinks: instruction.mediaLinks.map(({ s3key, url }) => ({ src: url, mediaKey: s3key })),
      doDont: instruction.doDont,
      isVideo: instruction.isVideo,
    }));

  return (
    <div className="InstructionsContent">
      <div className="InstructionsContent__header">{t('Instructions_header')}</div>
      <div className="InstructionsContent__instructions">
        {bestPractices.map(({ instructions }) => (
          <InstructionGroup
            key={instructions[0].text}
            instructionsGroup={createInstructionGroup(instructions)}
            showExampleLabel={false}
          />
        ))}
      </div>
    </div>
  );
};

export default InstructionsContent;
