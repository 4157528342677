import { type BestPracticeEnum, type CountryLocaleDto } from './dtos';
import { APP_PAGES, type MediaTypes } from './constants';

export interface CaseEnterForm {
  dpocCaseId: string;
  agreed: boolean;
}

export interface TicketInformationForm {
  agreed: boolean;
}

export interface Instruction {
  text?: string;
  mediaLinks?: MediaLink[];
  doDont?: BestPracticeEnum;
  isVideo?: boolean;
}

export interface MediaData {
  uploaded: boolean;
  uploading: boolean;
  url?: string;
  s3Key?: string;
  content: string;
  type: MediaTypes;
  file: File;
}

export interface MediaLink {
  src: string;
  mediaKey: string;
}

export interface APPLICATION_PAGE_INFO {
  getScreenName: (stepNumber?: number) => string;
  baseRoute: string;
  requiresStartedSubmission?: boolean;
  requiresNotFinishedSubmission?: boolean;
  requiresCountrySelected?: boolean;
  requiresCountryNotSelected?: boolean;
  canSwitchDevice: boolean;
}

export interface Region {
  regionTranslationKey: string;
  countries: CountryLocaleDto[];
}

export const APPLICATION_PAGES: Record<APP_PAGES, APPLICATION_PAGE_INFO> = {
  [APP_PAGES.countryPage]: {
    getScreenName: () => 'Country Selection',
    baseRoute: '',
    requiresStartedSubmission: false,
    requiresNotFinishedSubmission: false,
    requiresCountrySelected: false,
    requiresCountryNotSelected: true,
    canSwitchDevice: false,
  },
  [APP_PAGES.landingPage]: {
    getScreenName: () => 'Landing Page',
    baseRoute: 'landing',
    requiresStartedSubmission: false,
    requiresNotFinishedSubmission: false,
    requiresCountrySelected: true,
    canSwitchDevice: true,
  },
  [APP_PAGES.ticketInformation]: {
    getScreenName: () => 'Case Detail',
    baseRoute: 'cases/:caseId',
    requiresStartedSubmission: false,
    requiresNotFinishedSubmission: true,
    requiresCountrySelected: false,
    canSwitchDevice: true,
  },
  [APP_PAGES.instructions]: {
    getScreenName: () => 'Best Practices',
    baseRoute: 'instructions/:caseId',
    requiresStartedSubmission: true,
    requiresNotFinishedSubmission: true,
    requiresCountrySelected: false,
    canSwitchDevice: true,
  },
  [APP_PAGES.multipleUnitsInfo]: {
    getScreenName: (): string => 'Multiple Units Info',
    baseRoute: 'multipleUnitsInfo/:caseId',
    requiresStartedSubmission: true,
    requiresNotFinishedSubmission: true,
    requiresCountrySelected: false,
    canSwitchDevice: false,
  },
  [APP_PAGES.upload]: {
    getScreenName: (stepNumber?: number): string => `Photo Guide (STEP-${stepNumber ?? 'N/A'})`,
    baseRoute: 'upload/:caseId/:stepNumber',
    requiresStartedSubmission: true,
    requiresNotFinishedSubmission: true,
    requiresCountrySelected: false,
    canSwitchDevice: false,
  },
  [APP_PAGES.submit]: {
    getScreenName: () => 'Extra step - submit page',
    baseRoute: 'submit/:caseId',
    requiresStartedSubmission: true,
    requiresNotFinishedSubmission: true,
    requiresCountrySelected: false,
    canSwitchDevice: false,
  },
  [APP_PAGES.confirmation]: {
    getScreenName: () => 'Confirmation Page',
    baseRoute: 'confirmation/:caseId',
    requiresStartedSubmission: true,
    requiresNotFinishedSubmission: false,
    requiresCountrySelected: false,
    canSwitchDevice: false,
  },
  [APP_PAGES.termsAndConditions]: {
    getScreenName: () => 'Terms and Conditions Page',
    baseRoute: 'terms/:caseId',
    requiresStartedSubmission: false,
    requiresNotFinishedSubmission: true,
    requiresCountrySelected: false,
    canSwitchDevice: true,
  },
  [APP_PAGES.caseNotFound]: {
    getScreenName: () => 'Expired/Non-existing link',
    baseRoute: 'notfound',
    requiresStartedSubmission: false,
    requiresNotFinishedSubmission: false,
    requiresCountrySelected: false,
    canSwitchDevice: false,
  },
};
