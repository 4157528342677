import React, { type FC } from 'react';
import { type CountryLocaleDto, type LocaleDto } from '../../types/dtos';
import { useTranslation } from 'react-i18next';
import { EnvironmentTwoTone } from '@ant-design/icons';

import './CountryByLocation.scss';
import colors from '../../colors.module.scss';
import { AnalyticsButtonLink } from '../index';
import { ANALYTICS_EVENT_TYPES } from '../../types/constants';

interface CountryByLocationProps {
  country: CountryLocaleDto;
  onSelectLocale: (country: CountryLocaleDto, locale: LocaleDto) => void;
}

const CountryByLocation: FC<CountryByLocationProps> = ({ country, onSelectLocale }) => {
  const { t } = useTranslation();

  return (
    <div className="CountryByLocation">
      <div className="CountryByLocation__header">{t('LanguageSelection_locationSuggestion')}</div>
      <div className="CountryByLocation__select">
        <div className="CountryByLocation__select__country">
          <EnvironmentTwoTone twoToneColor={[colors.mainFeatureColor, colors.almostTransparentMainFeatureColor]} />
          <span className="CountryByLocation__select__country__name">{country.countryDisplayName}</span>
        </div>
        <div className="CountryByLocation__select__languages">
          {country.allowedLocales.map((locale) => (
            <AnalyticsButtonLink
              eventType={ANALYTICS_EVENT_TYPES.CountrySelectedByLocation}
              analyticsValue={JSON.stringify({ countryCode: country.countryCode, localeCode: locale.localeCode })}
              className="CountryByLocation__select__languages__language"
              key={locale.localeCode}
              onClick={() => {
                onSelectLocale(country, locale);
              }}
            >
              {locale.localeDisplayName}
            </AnalyticsButtonLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CountryByLocation;
