import React, { type FC, useCallback } from 'react';
import { Button } from 'antd';
import switchToMobile from '../../images/switchToMobile.svg';

import windowService from '../../services/windowService';
import { useCanSwitchDevice, useToggle } from '../../redux/hooks';
import SwitchToMobile from './SwitchToMobile';
import { usePassiveGetCaseDetail } from '../../redux/caseDetail/caseDetailHooks';
import { ANALYTICS_EVENT_TYPES } from '../../types/constants';
import { useAnalytics } from '../../redux/analytics/analyticsHooks';
import { AppModal } from '../index';

import './SwitchToMobileButton.scss';

const SwitchToMobileButton: FC = () => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [caseDetail] = usePassiveGetCaseDetail();
  const { log } = useAnalytics();

  const canSwitchDevice = useCanSwitchDevice();

  const handleToggleOpen = useCallback(() => {
    log(ANALYTICS_EVENT_TYPES.SwitchDeviceModal, isOpen ? 'close' : 'open');
    toggleIsOpen();
  }, [isOpen, log, toggleIsOpen]);

  if (!windowService.getIsDesktopScreen() || !canSwitchDevice) {
    return null;
  }

  return (
    <>
      <Button className="SwitchToMobileButton" onClick={handleToggleOpen}>
        <img className="SwitchToMobileButton__icon" src={switchToMobile} alt="" />
      </Button>
      <AppModal
        className="SwitchToMobileButtonModal"
        open={isOpen}
        onCancel={handleToggleOpen}
        width="100%"
        footer={null}
      >
        <SwitchToMobile dpocCaseId={caseDetail?.dpocCaseId} />
      </AppModal>
    </>
  );
};

export default SwitchToMobileButton;
