import React, { type FC } from 'react';
import { Trans } from 'react-i18next';
import { usePassiveGetCaseDetail } from '../../redux/caseDetail/caseDetailHooks';

import './MailToDpoc.scss';
import { useAppSelector } from '../../redux/hooks';
import { getDpocEmail } from '../../redux/help/helpSlice';

const createMailToLink = (email: string, dpocCaseId?: string): string =>
  dpocCaseId
    ? `mailto:${email}?subject=Product Complaint Photo App Defect ID: ${dpocCaseId}`
    : `mailto:${email}?subject=Product Complaint Photo App`;

const MailToDpoc: FC = () => {
  const [caseDetail] = usePassiveGetCaseDetail();

  const dpocEmail = useAppSelector(getDpocEmail);

  if (!dpocEmail) {
    return null;
  }

  return (
    <div className="MailToDpoc">
      <Trans
        i18nKey="faq-write-to-dpoc"
        values={{ contactEmail: dpocEmail }}
        components={{
          a: (
            <a href={createMailToLink(dpocEmail, caseDetail?.dpocCaseId)} className="MailToDpoc__link">
              {dpocEmail}
            </a>
          ),
        }}
      />
    </div>
  );
};

export default MailToDpoc;
