import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { MDSConfigProvider } from '@mds/merck-design-system';
import { RouterProvider } from 'react-router-dom';
import router from './router';

import './index.css';
import './variables.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <MDSConfigProvider theme="merckLightTheme">
      <RouterProvider router={router} />
    </MDSConfigProvider>
  </Provider>
);
