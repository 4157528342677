import { call, type Effect, put, select, actionChannel, take, delay, spawn } from 'redux-saga/effects';
import { type PayloadAction } from '@reduxjs/toolkit';
import fetchService from '../../services/fetchService';
import {
  type MediaData,
  type RequestMediaPayload,
  mediaCacheActions,
  getMedia,
  MediaFetchingStates,
} from './mediaCacheSlice';
import { buffers } from 'redux-saga';
import { MEDIA_PREFETCH_DELAY } from '../../types/constants';

export function* watchMediaRequest(action: PayloadAction<RequestMediaPayload>): Generator<Effect, void, any> {
  const { src, mediaKey } = action.payload;
  try {
    const mediaData: Record<string, MediaData> = yield select(getMedia);

    if (mediaData[mediaKey]?.state === MediaFetchingStates.INIT) {
      const mediaData = yield call(fetchService.fetchMedia, src);
      yield put(mediaCacheActions.loaded({ data: mediaData, src, mediaKey }));
    }
  } catch (e) {
    yield put(mediaCacheActions.loadFailed({ src, mediaKey }));
  }
}

function* mediaCacheSagas(): Generator<any, void, any> {
  const requestChanel = yield actionChannel(mediaCacheActions.request, buffers.expanding(16));
  while (true) {
    const action: PayloadAction<RequestMediaPayload> = yield take(requestChanel);
    yield spawn(watchMediaRequest, action);
    yield delay(MEDIA_PREFETCH_DELAY);
  }
}

export default mediaCacheSagas;
