import React, { type FC } from 'react';
import { AnalyticsLinkWithQuery, FooterButtons, Loading, TicketInformation } from '../components';
import { ANALYTICS_EVENT_TYPES } from '../types/constants';
import { routes } from '../router';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useCaseDetailFromQuery } from '../redux/caseDetail/caseDetailHooks';
import { Trans, useTranslation } from 'react-i18next';

import './MultipleUnitsInfoPage.scss';
import windowService from '../services/windowService';

const MultipleUnitsInfoPage: FC = () => {
  const { t } = useTranslation();

  const [caseDetail, isFetching] = useCaseDetailFromQuery();

  if (isFetching || !caseDetail) {
    return <Loading />;
  }

  return (
    <div className="MultipleUnitsInfoPage">
      {windowService.getIsDesktopScreen() && <TicketInformation caseDetail={caseDetail} />}
      <div className="MultipleUnitsInfoPage__content">
        <div className="MultipleUnitsInfoPage__content__header">{t('MultipleUnitsInfo_header')}</div>
        <div className="MultipleUnitsInfoPage__content__description">
          <Trans i18nKey="MultipleUnitsInfo_content" />
        </div>
      </div>

      <FooterButtons>
        <AnalyticsLinkWithQuery
          eventType={ANALYTICS_EVENT_TYPES.Back}
          type="primary"
          to={routes.instructions(caseDetail.dpocCaseId)}
        >
          <LeftOutlined /> {t('Navigation_back')}
        </AnalyticsLinkWithQuery>
        <AnalyticsLinkWithQuery
          eventType={ANALYTICS_EVENT_TYPES.Next}
          type="primary"
          to={routes.upload(caseDetail.dpocCaseId, 1)}
        >
          {t('MultipleUnitsInfo_confirm')} <RightOutlined />
        </AnalyticsLinkWithQuery>
      </FooterButtons>
    </div>
  );
};

export default MultipleUnitsInfoPage;
