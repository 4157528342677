import React, { type FC, useEffect } from 'react';
import { CaseNotFound } from '../components';
import { useAnalytics } from '../redux/analytics/analyticsHooks';
import { ANALYTICS_EVENT_TYPES } from '../types/constants';

const CaseNotFoundPage: FC = () => {
  const { log } = useAnalytics();

  useEffect(() => {
    log(ANALYTICS_EVENT_TYPES.CaseNotFound);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="CaseNotFoundPage">
      <CaseNotFound />
    </div>
  );
};

export default CaseNotFoundPage;
