import React, { type FC } from 'react';
import { useSubmit } from '../../redux/upload/uploadHooks';
import { routes } from '../../router';
import { AnalyticsButton, AnalyticsLinkWithQuery, FooterButtons } from '../index';
import { LeftOutlined } from '@ant-design/icons';
import { type CasePublicDto } from '../../types/dtos';
import { useLanguage } from '../../redux/language/languageHooks';
import { useTranslation } from 'react-i18next';
import { ANALYTICS_EVENT_TYPES } from '../../types/constants';

interface SubmitFooterProps {
  caseDetail: CasePublicDto;
}

const SubmitFooter: FC<SubmitFooterProps> = ({ caseDetail }) => {
  const { t } = useTranslation();
  const [submit, isSubmitting] = useSubmit();

  const { selectedLocale } = useLanguage();
  const totalIndices = caseDetail?.submission.stepInstructions[selectedLocale.localeCode].length ?? 0;

  const routeBack =
    totalIndices > 1
      ? routes.upload(caseDetail.dpocCaseId, totalIndices - 1)
      : routes.instructions(caseDetail.dpocCaseId);

  return (
    <FooterButtons>
      <AnalyticsLinkWithQuery eventType={ANALYTICS_EVENT_TYPES.Back} type="primary" to={routeBack}>
        <LeftOutlined /> {t('Navigation_back')}
      </AnalyticsLinkWithQuery>
      <AnalyticsButton eventType={ANALYTICS_EVENT_TYPES.Submit} type="primary" onClick={submit} loading={isSubmitting}>
        {t('Navigation_submit')}
      </AnalyticsButton>
    </FooterButtons>
  );
};

export default SubmitFooter;
