import React, { type FC } from 'react';
import { InfoCircleTwoTone, QuestionCircleOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { type CountryLocaleDto, type LocaleDto } from '../../types/dtos';
import CountryByLocation from './CountryByLocation';
import RegionCountrySelector from './RegionCountrySelector';
import { type Region } from '../../types/app';
import SelectionTooltip from './SelectionTooltip';
import { AppPopover } from '../index';

import './CountrySelector.scss';
import colors from '../../colors.module.scss';

interface CountrySelectorProps {
  locationBasedCountry: CountryLocaleDto | null;
  regions: Region[];
  onSelectLocale: (country: CountryLocaleDto, locale: LocaleDto) => void;
}

const CountrySelector: FC<CountrySelectorProps> = ({ locationBasedCountry, regions, onSelectLocale }) => {
  const { t } = useTranslation();

  return (
    <div className="CountrySelector">
      {locationBasedCountry && (
        <>
          <h4 className="CountrySelector__header">
            {t('LanguageSelection_selectLocationInfo')}
            <AppPopover title={<SelectionTooltip />}>
              <QuestionCircleOutlined className="CountrySelector__header__icon" />
            </AppPopover>
          </h4>
          <CountryByLocation country={locationBasedCountry} onSelectLocale={onSelectLocale} />
          <div className="CountrySelector__selectListHeader">{t('LanguageSelection_selectFromListBelow')}</div>
          <Divider className="CountrySelector__headerDivider" />
        </>
      )}
      {!locationBasedCountry && (
        <>
          <div className="CountrySelector__noCountryDetectedHeader">{t('LanguageSelection_selectLocationInfo')}</div>
          <div className="CountrySelector__noCountryDetectedSubHeader">
            <InfoCircleTwoTone
              twoToneColor={[colors.mainFeatureColor, colors.almostTransparentMainFeatureColor]}
              className="CountrySelector__noCountryDetectedSubHeader__icon"
            />
            <div className="CountrySelector__noCountryDetectedSubHeader__description">
              {t('LanguageSelection_selectLocationInfo_tooltip')}
            </div>
          </div>
        </>
      )}
      <RegionCountrySelector regions={regions} onSelectLocale={onSelectLocale} />
    </div>
  );
};

export default CountrySelector;
