import { type MediaData, MediaFetchingStates, mediaCacheActions, getMedia } from './mediaCacheSlice';
import { useAppDispatch, useAppSelector } from '../hooks';

export const useMediaLoadingCache = (
  src: string,
  mediaKey: string
): { isLoading: boolean; data: string | undefined } => {
  const dispatch = useAppDispatch();
  const mediaDataMap = useAppSelector(getMedia);
  const mediaData: MediaData | undefined = mediaDataMap[mediaKey];

  if (!mediaData) {
    dispatch(mediaCacheActions.request({ src, mediaKey }));
  }

  const isLoading =
    mediaData?.state === MediaFetchingStates.INIT || mediaData?.state === MediaFetchingStates.LOADING_ALREADY;

  return { isLoading, data: mediaData?.data };
};
