import React from 'react';
import ErrorBoundaryContent from './ErrorBoundaryContent';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return {
      hasError: true,
    };
  }

  render(): React.JSX.Element {
    if (this.state.hasError) {
      return <ErrorBoundaryContent />;
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
