import React, { type FC, useCallback } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import { useReset } from '../../redux/caseDetail/caseDetailHooks';
import './ConfirmationHeader.scss';
import { useNavigate } from 'react-router';
import { routes } from '../../router';
import windowService from '../../services/windowService';
import { AnalyticsButton } from '../index';
import { ANALYTICS_EVENT_TYPES } from '../../types/constants';

const ConfirmationHeader: FC = () => {
  const { t } = useTranslation();

  const reset = useReset();
  const navigate = useNavigate();
  const handleNavigateHome = useCallback(() => {
    reset();
    navigate(routes.landingPage());
  }, [navigate, reset]);

  return (
    <div className="ConfirmationHeader">
      {!windowService.getIsDesktopScreen() && <CheckCircleFilled className="ConfirmationHeader__icon" />}
      <div className="ConfirmationHeader__title">{t('Confirmation_header')}</div>
      {windowService.getIsDesktopScreen() && <CheckCircleFilled className="ConfirmationHeader__icon" />}
      <div className="ConfirmationHeader__description">
        <Trans
          i18nKey="Confirmation_description"
          components={{
            nobr: <span className="no-break" />,
          }}
        />
      </div>
      <AnalyticsButton eventType={ANALYTICS_EVENT_TYPES.BackToHome} type="primary" onClick={handleNavigateHome}>
        {t('Confirmation_navigateHome')}
      </AnalyticsButton>
    </div>
  );
};

export default ConfirmationHeader;
