import { all, call, type Effect, type ForkEffect, put, takeEvery } from 'redux-saga/effects';
import {
  countrySelectionActions,
  type FetchCountriesPayload,
  type SelectLanguageAndLocalePayload,
} from './countrySelectionSlice';
import { type CountryLocaleDto, type LanguageSelectionTranslationsDto } from '../../types/dtos';
import fetchService from '../../services/fetchService';
import localizationService from '../../services/localizationService';
import { type PayloadAction } from '@reduxjs/toolkit';
import { functionDoFetchTranslations } from '../language/languageSaga';
import { routes } from '../../router';
import { languageActions } from '../language/languageSlice';

function* watchFetchCountry(
  action: PayloadAction<FetchCountriesPayload>
): Generator<Effect, void, [CountryLocaleDto[], LanguageSelectionTranslationsDto]> {
  const { navigate } = action.payload;

  try {
    const [countries, languageSelectionTranslations] = yield all([
      call(fetchService.fetchCountries),
      call(fetchService.fetchLanguageSelectionTranslations),
    ]);

    yield call(localizationService.addTranslations, languageSelectionTranslations.translations);
    yield put(
      countrySelectionActions.fetchCountriesAndTranslationsFinished({ countries, languageSelectionTranslations })
    );

    const preferredUserLocaleCode = localizationService.getPreferredLocaleCode(languageSelectionTranslations.locales);
    if (preferredUserLocaleCode) {
      yield call(localizationService.changeLanguage, preferredUserLocaleCode);
      yield put(languageActions.selectLanguage({ userSelect: false }));
    }

    // If we have only single country, we can immediately select it. This code can be removed, when we will have more countries on production.
    if (countries.length === 1) {
      yield put(
        countrySelectionActions.selectLocale({
          country: countries[0],
          localeCode: countries[0].defaultLocaleCode,
          navigate,
        })
      );
    }
  } catch {
    yield put(countrySelectionActions.fetchCountriesAndTranslationsFailed());
  }
}

function* watchSelectLocale(
  action: PayloadAction<SelectLanguageAndLocalePayload>
): Generator<Effect, void, [CountryLocaleDto[], LanguageSelectionTranslationsDto]> {
  try {
    const { country, localeCode, navigate } = action.payload;

    yield call(functionDoFetchTranslations, country.countryCode);
    yield call(localizationService.changeLanguage, localeCode);
    navigate(routes.landingPage());
    yield put(countrySelectionActions.selectLocaleFinished());
  } catch {
    yield put(countrySelectionActions.selectLocaleFailed());
  }
}

export function* countrySelectionSagas(): Generator<ForkEffect, void> {
  yield takeEvery(countrySelectionActions.fetchCountriesAndTranslations, watchFetchCountry);
  yield takeEvery(countrySelectionActions.selectLocale, watchSelectLocale);
}

export default countrySelectionSagas;
