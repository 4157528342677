import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '../store';
import { type CountryLocaleDto } from '../../types/dtos';
import { countrySelectionActions } from '../countrySelection/countrySelectionSlice';

export interface CountrySlice {
  value?: CountryLocaleDto;
  fetching: boolean;
  error: boolean;
}

export const initialState: CountrySlice = {
  value: undefined,
  fetching: false,
  error: false,
};

export const getCountry = (state: RootState): CountryLocaleDto | undefined => state.country.value;
export const getCountryFetching = (state: RootState): boolean => state.country.fetching;
export const getCountryFetchingError = (state: RootState): boolean => state.country.error;

export interface FetchCountryPayload {
  countryCode: string;
}

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    fetchCountry: (state, _action: PayloadAction<FetchCountryPayload>) => {
      state.value = undefined;
      state.fetching = true;
    },
    fetchCountryFinished: (state, action: PayloadAction<CountryLocaleDto>) => {
      state.value = action.payload;
      state.fetching = false;
      state.error = false;
    },
    fetchCountryFailed: (state) => {
      state.fetching = false;
      state.error = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(countrySelectionActions.selectLocale, (state, action) => {
      state.value = action.payload.country;
    });
  },
});

export const { actions: countryActions, reducer: countryReducer } = countrySlice;
