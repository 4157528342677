import React, { type FC } from 'react';
import { Loading, UploadFooter, UploadStep } from '../components';
import { useCaseDetailFromQuery } from '../redux/caseDetail/caseDetailHooks';
import { useAppParams } from '../redux/hooks';
import windowService from '../services/windowService';
import TicketInformation from '../components/TicketInformation/TicketInformation';

const UploadPage: FC = () => {
  const { stepNumber } = useAppParams();
  const [caseDetail, isFetching] = useCaseDetailFromQuery();

  if (isFetching || !caseDetail || !stepNumber) {
    return <Loading />;
  }

  return (
    <div className="UploadPage">
      {windowService.getIsDesktopScreen() && <TicketInformation caseDetail={caseDetail} />}
      <UploadStep caseDetail={caseDetail} stepNumber={stepNumber} />
      <UploadFooter caseDetail={caseDetail} stepNumber={stepNumber} />
    </div>
  );
};

export default UploadPage;
