import React, { type FC } from 'react';

import { type Instruction } from '../../types/app';
import MediaCarousel, { MediaWrapper } from './MediaCarousel';
import InstructionMedia from './InstructionMedia';
import './InstructionGroup.scss';

interface InstructionGroupProps {
  instructionsGroup: Instruction[];
  showExampleLabel: boolean;
}

const InstructionGroup: FC<InstructionGroupProps> = ({ instructionsGroup, showExampleLabel }) => {
  return (
    <MediaCarousel className="InstructionGroup">
      {instructionsGroup.map((instruction, instructionIndex) => (
        <div key={instruction.text ?? instructionIndex} className="InstructionGroup__instruction">
          {instruction.mediaLinks?.map((mediaLink) => (
            <MediaWrapper className="InstructionGroup__instruction__imageWrapper" key={`${mediaLink.mediaKey}`}>
              <InstructionMedia instruction={instruction} mediaLink={mediaLink} showExampleLabel={showExampleLabel} />
            </MediaWrapper>
          ))}
        </div>
      ))}
    </MediaCarousel>
  );
};

export default InstructionGroup;
