import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { AnalyticsLinkWithQuery, FooterButtons } from '../index';
import { routes } from '../../router';
import { useGetStepMedia } from '../../redux/upload/uploadHooks';
import { type CasePublicDto } from '../../types/dtos';
import { useLanguage } from '../../redux/language/languageHooks';
import { ANALYTICS_EVENT_TYPES, MediaTypes } from '../../types/constants';

interface UploadFooterProps {
  caseDetail: CasePublicDto;
  stepNumber: number;
}

const UploadFooter: FC<UploadFooterProps> = ({ caseDetail, stepNumber }) => {
  const { t } = useTranslation();
  const { selectedLocale } = useLanguage();
  const stepMedia = useGetStepMedia(stepNumber);

  const photos = stepMedia.filter(({ type }) => type === MediaTypes.IMAGE);
  const videos = stepMedia.filter(({ type }) => type === MediaTypes.VIDEO);

  const totalIndices = caseDetail.submission.stepInstructions[selectedLocale.localeCode].length;

  const stepInstruction = caseDetail.submission.stepInstructions[selectedLocale.localeCode][stepNumber - 1];

  const isNextEnabled =
    photos.length >= stepInstruction.numPictureRequested && videos.length >= stepInstruction.numVideoRequested;

  const routeBack =
    stepNumber > 1
      ? routes.upload(caseDetail.dpocCaseId, stepNumber - 1)
      : caseDetail.multipleUnits
      ? routes.multipleUnitsInfo(caseDetail.dpocCaseId)
      : routes.instructions(caseDetail.dpocCaseId);

  const routeNext =
    stepNumber + 1 < totalIndices // + 1 - the last step is 'extra' step on submit page
      ? routes.upload(caseDetail.dpocCaseId, stepNumber + 1)
      : routes.submit(caseDetail.dpocCaseId);

  return (
    <FooterButtons>
      <AnalyticsLinkWithQuery eventType={ANALYTICS_EVENT_TYPES.Back} type="primary" to={routeBack}>
        <LeftOutlined /> {t('Navigation_back')}
      </AnalyticsLinkWithQuery>
      <AnalyticsLinkWithQuery
        eventType={ANALYTICS_EVENT_TYPES.Next}
        type="primary"
        to={routeNext}
        disabled={!isNextEnabled}
      >
        {t('Navigation_next')} <RightOutlined />
      </AnalyticsLinkWithQuery>
    </FooterButtons>
  );
};

export default UploadFooter;
