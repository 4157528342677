import React, { type FC } from 'react';
import { Trans } from 'react-i18next';
import { routes } from '../../router';
import { type CheckboxChangeEvent } from 'antd/es/checkbox';

import './TermsCheckbox.scss';
import { AnalyticsCheckbox, AnalyticsNavLink } from '../index';
import { ANALYTICS_EVENT_TYPES } from '../../types/constants';

interface TermsCheckboxProps {
  checked?: boolean;
  onChange?: (value: CheckboxChangeEvent) => void;
  dpocCaseId?: string;
}

const TermsCheckbox: FC<TermsCheckboxProps> = ({ checked, onChange, dpocCaseId }) => (
  <AnalyticsCheckbox
    eventType={ANALYTICS_EVENT_TYPES.TermsConditionAgree}
    analyticsValue={!checked ? 'checked' : 'unchecked'}
    checked={checked}
    onChange={onChange}
    className="TermsCheckbox"
  >
    <Trans
      i18nKey="CaseEnter_agreeConditions"
      components={{
        a: dpocCaseId ? (
          <AnalyticsNavLink
            eventType={ANALYTICS_EVENT_TYPES.TermsConditionRead}
            className="TermsCheckbox__link"
            to={routes.termsAndConditions(dpocCaseId)}
          >
            {' '}
          </AnalyticsNavLink>
        ) : (
          <span />
        ),
      }}
    />
  </AnalyticsCheckbox>
);

export default TermsCheckbox;
