import React, { type FC, useCallback } from 'react';

import './CaseEnterBody.scss';
import { Form, Input } from 'antd';
import CaseIdHeader from './CaseIdHeader';
import { type CaseEnterForm } from '../../types/app';
import { useTranslation } from 'react-i18next';
import { useCaseDetail } from '../../redux/caseDetail/caseDetailHooks';
import { ANALYTICS_EVENT_TYPES } from '../../types/constants';
import { AnalyticsButton } from '../index';

const CaseEnterBody: FC = () => {
  const [form] = Form.useForm<CaseEnterForm>();
  const { t } = useTranslation();
  const [_caseDetail, fetchCaseDetail, isFetching] = useCaseDetail();

  const caseId = Form.useWatch('caseId', form);

  const handleFinish = useCallback(() => {
    fetchCaseDetail(caseId, false);
  }, [caseId, fetchCaseDetail]);

  return (
    <div className="CaseEnterBody">
      <Form className="CaseEnterBody__form" requiredMark={false} form={form} onFinish={handleFinish}>
        <Form.Item
          name="caseId"
          label={<CaseIdHeader />}
          rules={[
            {
              required: true,
              message: t<string>('CaseEnter_caseIdRequired'),
            },
          ]}
        >
          <Input placeholder={t('CaseEnter_caseId') ?? undefined} />
        </Form.Item>
        <div className="CaseEnterBody__form__bottomLine">
          <Form.Item>
            <AnalyticsButton
              eventType={ANALYTICS_EVENT_TYPES.CaseIdEntered}
              analyticsValue={caseId}
              type="primary"
              className="CaseEnterBody__form__bottomLine__submit"
              htmlType="submit"
              loading={isFetching}
            >
              {t('CaseEnter_submit')}
            </AnalyticsButton>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default CaseEnterBody;
