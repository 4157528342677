import { all, fork, type AllEffect, type ForkEffect } from 'redux-saga/effects';
import analyticsSagas from './analytics/analyticsSaga';
import countrySelectionSagas from './countrySelection/countrySelectionSaga';
import countrySagas from './country/countrySaga';
import caseDetailSagas from './caseDetail/caseDetailSaga';
import helpSagas from './help/helpSaga';
import languageSagas from './language/languageSaga';
import mediaCacheSaga from './mediaCache/mediaCacheSaga';
import uploadSagas from './upload/uploadSaga';

export default function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
  yield all([
    fork(analyticsSagas),
    fork(caseDetailSagas),
    fork(countrySelectionSagas),
    fork(countrySagas),
    fork(helpSagas),
    fork(languageSagas),
    fork(mediaCacheSaga),
    fork(uploadSagas),
  ]);
}
