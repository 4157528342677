import { call, type Effect, type ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { countryActions, type FetchCountryPayload } from './countrySlice';
import { type PayloadAction } from '@reduxjs/toolkit';
import fetchService, { type FetchError } from '../../services/fetchService';
import { notification } from 'antd';
import { type CountryLocaleDto } from '../../types/dtos';
import localizationService from '../../services/localizationService';

function* watchFetchCountry(action: PayloadAction<FetchCountryPayload>): Generator<Effect, void, CountryLocaleDto> {
  try {
    const country = yield call(fetchService.fetchCountry, action.payload.countryCode);
    yield put(countryActions.fetchCountryFinished(country));

    // TODO CountryLocaleDto should have defaultLocaleCode
    yield call(localizationService.changeLanguage, country.allowedLocales[0].localeCode);
  } catch (e) {
    if ((e as FetchError).status === 404) {
      notification.error({
        message: 'Country not found',
        description: `Unable to load country details for country with code '${action.payload.countryCode ?? ''}'`,
      });
    }
    yield put(countryActions.fetchCountryFailed());
  }
}

export function* countrySagas(): Generator<ForkEffect, void> {
  yield takeEvery(countryActions.fetchCountry, watchFetchCountry);
}

export default countrySagas;
