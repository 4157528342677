import React, { type FC } from 'react';
import { type MediaData } from '../../types/app';
import { type MediaTypes } from '../../types/constants';
import UploadedMediaSummaryHeader from './UploadedMediaSummaryHeader';
import UploadedMediaSummaryMedia from './UploadedMediaSummaryMedia';

import './UploadedMediaSummary.scss';

interface UploadedMediaSummaryProps {
  mediaType: MediaTypes;
  stepMedia: MediaData[];
  isMandatory: boolean;
}

const UploadedMediaSummary: FC<UploadedMediaSummaryProps> = ({ mediaType, stepMedia, isMandatory }) => {
  return (
    <div className="UploadedMediaSummary">
      <UploadedMediaSummaryHeader stepMedia={stepMedia} mediaType={mediaType} isMandatory={isMandatory} />
      <UploadedMediaSummaryMedia mediaType={mediaType} stepMedia={stepMedia} />
    </div>
  );
};

export default UploadedMediaSummary;
