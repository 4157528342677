import React, { type FC } from 'react';
import { type ImageProps } from 'rc-image/lib/Image';
import { useMediaLoadingCache } from '../../redux/mediaCache/mediaCacheHook';
import { CenteredSpin } from '../index';
import AnalyticsImage from '../analytics/AnalyticsImage';
import { ANALYTICS_EVENT_TYPES } from '../../types/constants';

type LoadingImageProps = ImageProps & {
  src: string;
  mediaKey: string;
};

const LoadingImage: FC<LoadingImageProps> = ({ src, mediaKey, ...rest }) => {
  const { isLoading, data } = useMediaLoadingCache(src, mediaKey);

  if (isLoading) {
    return <CenteredSpin size="default" />;
  }

  return <AnalyticsImage eventType={ANALYTICS_EVENT_TYPES.ViewExamplePhotoFull} src={data} {...rest} />;
};

export default LoadingImage;
