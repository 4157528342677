import React, { type FC, useCallback, useMemo } from 'react';
import { Dropdown, type MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { type MenuInfo } from 'rc-menu/lib/interface';
import { useLanguage } from '../../redux/language/languageHooks';

import { ANALYTICS_EVENT_TYPES } from '../../types/constants';
import { useAnalytics } from '../../redux/analytics/analyticsHooks';

import './Language.scss';

const Language: FC = () => {
  const { locales, selectedLocale, isUserSelectable, selectLanguageCode } = useLanguage();
  const { log } = useAnalytics();

  const items: MenuProps['items'] = useMemo(
    () =>
      locales?.map((locale) => ({
        label: locale.localeDisplayName,
        key: locale.localeCode,
      })) ?? [{ key: 'en-US', label: 'English' }],
    [locales]
  );

  const handleClick = useCallback(
    async (info: MenuInfo) => {
      log(ANALYTICS_EVENT_TYPES.LanguageChanged, info.key);
      await selectLanguageCode(info.key, true);
    },
    [log, selectLanguageCode]
  );

  const menu = useMemo(
    () => ({
      items,
      onClick: handleClick,
    }),
    [handleClick, items]
  );

  if (!isUserSelectable) {
    return null;
  }

  return (
    <Dropdown className="Language" menu={menu}>
      <span className="Language__content">
        {selectedLocale.localeDisplayName}
        <DownOutlined />
      </span>
    </Dropdown>
  );
};

export default Language;
