import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import SwitchToShort from './SwitchToShort';
import { useCanSwitchDevice } from '../../redux/hooks';

import './HelpIntro.scss';
import MailToDpoc from './MailToDpoc';

interface HelpIntroProps {
  onFaqClick: () => void;
}

const HelpIntro: FC<HelpIntroProps> = ({ onFaqClick }) => {
  const { t } = useTranslation();
  const canSwitchDevice = useCanSwitchDevice();

  return (
    <div className="HelpIntro">
      <div className="HelpIntro__title">{t('faq-got-questions-title')}</div>
      <div className="HelpIntro__description">{t('faq-find-your-answers')}:</div>
      <Button className="HelpIntro__link" size="large" type="link" onClick={onFaqClick}>
        {t('faq-faq-title')} <ArrowRightOutlined />
      </Button>
      {canSwitchDevice && <SwitchToShort />}
      <MailToDpoc />
    </div>
  );
};

export default HelpIntro;
