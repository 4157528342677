import { type CountryLocaleDto, type LocaleDto } from '../../types/dtos';

import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppRouter, useAppSelector } from '../hooks';
import {
  countrySelectionActions,
  getCountries,
  getCountriesAndTranslationsFetching,
  getCountriesAndTranslationsFetchingError,
  getLanguageSelectionTranslations,
} from './countrySelectionSlice';
import { type Region } from '../../types/app';
import { useNavigate } from 'react-router';
import { routes } from '../../router';
import { getCountry } from '../country/countrySlice';
import windowService from '../../services/windowService';

export const useCountrySelection: () => {
  locationBasedCountry: CountryLocaleDto | null;
  regions: Region[] | null;
  loading: boolean;
  handleSelectLocale: (country: CountryLocaleDto, locale: LocaleDto) => void;
} = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const countries = useAppSelector(getCountries);

  const languageSelectionTranslations = useAppSelector(getLanguageSelectionTranslations);
  const fetching = useAppSelector(getCountriesAndTranslationsFetching);
  const error = useAppSelector(getCountriesAndTranslationsFetchingError);

  if (!countries && !languageSelectionTranslations && !fetching && !error) {
    dispatch(countrySelectionActions.fetchCountriesAndTranslations({ navigate }));
  }

  const handleSelectLocale = useCallback(
    (country: CountryLocaleDto, locale: LocaleDto) => {
      dispatch(countrySelectionActions.selectLocale({ country, localeCode: locale.localeCode, navigate }));
    },
    [dispatch, navigate]
  );

  const regions = useMemo(() => {
    if (!countries) {
      return null;
    }

    const regionsMap = countries.reduce((acc: Record<Region['regionTranslationKey'], Region>, country) => {
      const region = (acc[country.regionTranslationKey] = acc[country.regionTranslationKey] ?? {
        regionTranslationKey: country.regionTranslationKey,
        countries: [],
      });
      region.countries.push(country);
      return acc;
    }, {});

    return Object.values(regionsMap);
  }, [countries]);

  return {
    // TODO: BE is not ready now - need to fetch location that is automaticaly detected by IP adress on BE
    locationBasedCountry: null,
    regions,
    loading: fetching,
    handleSelectLocale,
  };
};

export const useEnsureCountrySelected: () => void = () => {
  const navigate = useNavigate();
  const { activePage } = useAppRouter();

  const country = useAppSelector(getCountry);

  const countryCode = country?.countryCode ?? windowService.getCountrySearchParam();

  useEffect(() => {
    if (activePage.requiresCountrySelected && !countryCode) {
      navigate(routes.countryPage());
    }
  }, [navigate, activePage.requiresNotFinishedSubmission, activePage.requiresCountrySelected, country, countryCode]);
};

export const useEnsureCountryNotSelected: () => void = () => {
  const navigate = useNavigate();
  const { activePage } = useAppRouter();

  const country = useAppSelector(getCountry);

  const countryCode = country?.countryCode ?? windowService.getCountrySearchParam();

  useEffect(() => {
    if (activePage.requiresCountryNotSelected && countryCode) {
      navigate(routes.landingPage());
    }
  }, [
    navigate,
    activePage.requiresNotFinishedSubmission,
    activePage.requiresCountrySelected,
    country,
    countryCode,
    activePage.requiresCountryNotSelected,
  ]);
};
