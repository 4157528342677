import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '../store';
import { type FaqDto, type FaqPageDto } from '../../types/dtos';

export interface HelpSlice {
  faqList?: Array<FaqDto | FaqPageDto>;
  dpocEmail?: string;
  isOpen: boolean;
  fetching: boolean;
  reportingMedia: boolean;
  mediaReported: boolean;
}

export const initialState: HelpSlice = {
  faqList: undefined,
  dpocEmail: undefined,
  isOpen: false,
  fetching: false,
  reportingMedia: false,
  mediaReported: false,
};

export const gatFaqList = (state: RootState): HelpSlice['faqList'] => state.help.faqList;
export const getDpocEmail = (state: RootState): HelpSlice['dpocEmail'] => state.help.dpocEmail;
export const getIsHelpOpen = (state: RootState): HelpSlice['isOpen'] => state.help.isOpen;
export const getIsFaqFetching = (state: RootState): HelpSlice['fetching'] => state.help.fetching;
export const getIsReportingMedia = (state: RootState): HelpSlice['reportingMedia'] => state.help.reportingMedia;
export const getIsMediaReported = (state: RootState): HelpSlice['mediaReported'] => state.help.mediaReported;

export interface GetFaqFinishedPayload {
  faqList: Array<FaqDto | FaqPageDto>;
  dpocEmail: string;
}

export const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
      state.fetching = true;
      state.reportingMedia = false;
      state.mediaReported = false;
    },
    openFinished: (state, { payload: { faqList, dpocEmail } }: PayloadAction<GetFaqFinishedPayload>) => {
      state.faqList = faqList;
      state.dpocEmail = dpocEmail;
      state.fetching = false;
    },
    openFailed: (state) => {
      state.fetching = false;
    },
    close: (state) => {
      state.isOpen = false;
    },
    reportMedia: (state) => {
      state.reportingMedia = true;
    },
    reportMediaFinished: (state) => {
      state.reportingMedia = false;
      state.mediaReported = true;
    },
    reportMediaFailed: (state) => {
      state.reportingMedia = false;
    },
  },
});

export const { actions: helpActions, reducer: helpReducer } = helpSlice;
