import React, { type FC } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';

import './CaseIdTooltip.scss';
import { useTranslation } from 'react-i18next';

const CaseIdTooltip: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="CaseIdTooltip">
      <InfoCircleFilled className="CaseIdTooltip__icon" />
      <div className="CaseIdTooltip__content">{t('CaseEnter_caseIdPopover')}</div>
    </div>
  );
};

export default CaseIdTooltip;
