import React, { type FC, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Button } from 'antd';
import './LinkWithQuery.scss';
import { type ButtonType } from 'antd/es/button';

interface Props {
  to: string;
  type?: ButtonType;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

const LinkWithQuery: FC<Props> = ({ children, to, type, disabled, onClick }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
    navigate(to + search);
  }, [navigate, onClick, search, to]);

  return (
    <Button type={type} className="LinkWithQuery" disabled={disabled} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default LinkWithQuery;
