import { type FC } from 'react';
import React from 'react';
import { Carousel } from 'antd';

import './MediaCarousel.scss';
import classNames from 'classnames';
import windowService from '../../services/windowService';

interface MediaCarouselProps {
  children: React.ReactNode;
  className?: string;
}

interface MediaWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const MediaWrapper: FC<MediaWrapperProps> = ({ children, className }) => (
  <div className={classNames('MediaWrapper', className)}>{children}</div>
);

const MediaCarousel: FC<MediaCarouselProps> = ({ children, className }) => {
  return windowService.getIsCarouselScreen() ? (
    <div className={classNames('MediaCarousel', 'MediaCarousel--carousel', className)}>
      <Carousel dotPosition="top">{children}</Carousel>
    </div>
  ) : (
    <div className={classNames('MediaCarousel', 'MediaCarousel--noCarousel', className)}>{children}</div>
  );
};

export default MediaCarousel;
