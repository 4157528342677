import { CAROUSEL_MAX_SCREEN_WIDTH, COUNTRY_SEARCH_PARAM_NAMES } from '../types/constants';

const getIsTouchEnabled: () => boolean = () =>
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  (navigator as any as { msMaxTouchPoints: number }).msMaxTouchPoints > 0;

const getIsCarouselScreen: () => boolean = () => window.screen.width <= CAROUSEL_MAX_SCREEN_WIDTH;
const getIsDesktopScreen: () => boolean = () => !getIsCarouselScreen() && !getIsTouchEnabled();

const getCountrySearchParam: () => string | null = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const countryCode = COUNTRY_SEARCH_PARAM_NAMES.reduce<null | string>(
    (acc, paramName) => acc ?? searchParams.get(paramName),
    null
  );

  // TODO - default country code / country selection dialog / country chosen by domain
  return countryCode;
};

const windowService = {
  getIsTouchEnabled,
  getIsCarouselScreen,
  getIsDesktopScreen,
  getCountrySearchParam,
};

export default windowService;
