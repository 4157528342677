import React, { type FC } from 'react';
import { ApplicationTitle, Loading } from '../components';
import CountrySelector from '../components/Country/CountrySelector';
import { useCountrySelection } from '../redux/countrySelection/countrySelectionHooks';

const CountryPage: FC = () => {
  const { locationBasedCountry, regions, loading, handleSelectLocale } = useCountrySelection();

  if (loading || !regions) {
    return <Loading />;
  }
  return (
    <div className="CountryPage">
      <ApplicationTitle titleCode="LanguageSelection_photoAppName" />
      <CountrySelector
        locationBasedCountry={locationBasedCountry}
        regions={regions}
        onSelectLocale={handleSelectLocale}
      />
    </div>
  );
};

export default CountryPage;
