import React, { type FC } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import CaseIdTooltip from './CaseIdTooltip';
import { AppPopover } from '../index';

import './CaseIdHeader.scss';

const CaseIdHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <span className="CaseIdHeader">
      {t('CaseEnter_caseId')}{' '}
      <AppPopover title={<CaseIdTooltip />}>
        <QuestionCircleOutlined className="CaseIdHeader__icon" />
      </AppPopover>
    </span>
  );
};

export default CaseIdHeader;
