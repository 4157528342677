import React, { type FC } from 'react';
import { Trans } from 'react-i18next';

import './MultipleUnitsDescription.scss';

const MultipleUnitsDescription: FC = () => {
  return (
    <div className="MultipleUnitsDescription">
      <Trans i18nKey="MultipleUnitsStep_description" />
    </div>
  );
};

export default MultipleUnitsDescription;
