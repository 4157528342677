import React, { type FC, useEffect } from 'react';

import { CaseEnterBody, CaseEnterHeader, Loading, Policies, SwitchToMobile } from '../components';
import windowService from '../services/windowService';
import { useFetchTranslations } from '../redux/language/languageHooks';

const CaseEnterPage: FC = () => {
  const { translationsLoaded, fetchCountryTranslations } = useFetchTranslations();

  useEffect(() => {
    fetchCountryTranslations();
    // eslint-disable-next-line
  }, []);

  if (!translationsLoaded) {
    return <Loading />;
  }

  return (
    <div className="CaseEnterPage">
      <CaseEnterHeader />
      {windowService.getIsDesktopScreen() && <SwitchToMobile />}
      <CaseEnterBody />
      <Policies />
    </div>
  );
};

export default CaseEnterPage;
