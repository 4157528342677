import { Divider } from 'antd';
import React, { type FC } from 'react';

import './Section.scss';

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

const Section: FC<SectionProps> = ({ title, children }) => (
  <div className="Section">
    <div className="Section__header">
      <div className="Section__header__title">{title}</div>
      <Divider />
    </div>
    {children}
  </div>
);

export default Section;
