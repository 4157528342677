import React, { type FC } from 'react';
import { type UploadProps } from 'antd/es/upload/interface';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

import './MediaDragger.scss';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

const MediaDragger: FC<UploadProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Dragger className="MediaDragger" {...props}>
      <InboxOutlined className="MediaDragger__icon" />
      <div className="MediaDragger__title">{props.children}</div>
      <div className="MediaDragger__description">{t('Upload_dragAndDropDescription')}</div>
    </Dragger>
  );
};

export default MediaDragger;
