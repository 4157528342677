import React, { type FC, useMemo } from 'react';

import './FaqMenu.scss';
import { type FaqDto, type FaqPageDto } from '../../types/dtos';
import { useTranslation } from 'react-i18next';
import { Menu, type MenuProps } from 'antd';
import { type TFunction } from 'i18next';
import MailToDpoc from './MailToDpoc';

type MenuItems = Required<MenuProps>['items'];

interface FaqMenuProps {
  faqList: Array<FaqDto | FaqPageDto>;
  selectFaqItem: (faqItem: FaqPageDto) => void;
}

const getMenuItem: (
  faqItem: FaqDto | FaqPageDto,
  isRoot: boolean,
  selectFaqItem: (faqItem: FaqPageDto) => void,
  t: TFunction<'translation'>
) => MenuItems[number] = (faqItem, isRoot, selectFaqItem, t) => {
  return {
    key: faqItem.titleKey,
    label: t(faqItem.titleKey),
    className: isRoot ? 'FaqMenu__menu__rootItem' : 'FaqMenu__menu__childItem',
    children: 'children' in faqItem ? getMenuItems(faqItem.children, false, selectFaqItem, t) : undefined,
    onClick:
      'children' in faqItem
        ? undefined
        : () => {
            selectFaqItem(faqItem);
          },
  };
};

const getMenuItems: (
  faqList: Array<FaqDto | FaqPageDto>,
  isRoot: boolean,
  selectFaqItem: (faqItem: FaqPageDto) => void,
  t: TFunction<'translation'>
) => MenuItems = (faqList, isRoot, selectFaqItem, t) => {
  return faqList.map((faqItem) => getMenuItem(faqItem, isRoot, selectFaqItem, t));
};

const FaqMenu: FC<FaqMenuProps> = ({ faqList, selectFaqItem }) => {
  const { t } = useTranslation();

  const items = useMemo(() => getMenuItems(faqList, true, selectFaqItem, t), [faqList, selectFaqItem, t]);

  return (
    <div className="FaqMenu">
      <div className="FaqMenu__title">{t('faq-faq-title')}</div>
      <Menu className="FaqMenu__menu" items={items} mode="inline" />
      <MailToDpoc />
    </div>
  );
};

export default FaqMenu;
