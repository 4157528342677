import React, { type FC } from 'react';
import { ExtraStep, Loading, SubmitFooter } from '../components';
import { useCaseDetailFromQuery } from '../redux/caseDetail/caseDetailHooks';
import windowService from '../services/windowService';
import TicketInformation from '../components/TicketInformation/TicketInformation';

const SubmitPage: FC = () => {
  const [caseDetail, isFetching] = useCaseDetailFromQuery();

  if (isFetching || !caseDetail) {
    return <Loading />;
  }

  return (
    <div className="SubmitPage">
      {windowService.getIsDesktopScreen() && <TicketInformation caseDetail={caseDetail} />}
      <ExtraStep caseDetail={caseDetail} />
      <SubmitFooter caseDetail={caseDetail} />
    </div>
  );
};

export default SubmitPage;
