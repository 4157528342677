import { type PayloadAction } from '@reduxjs/toolkit';
import { call, type Effect, type ForkEffect, put, select, takeEvery } from 'redux-saga/effects';
import { analyticsActions, type LogAnalyticsPayload } from './analyticsSlice';
import { getCaseDetail } from '../caseDetail/caseDetailSlice';
import { type AnalyticsDto, type CasePublicDto } from '../../types/dtos';
import fetchService from '../../services/fetchService';
import sessionService from '../../services/sessionService';

function* watchAnalyticsLog(action: PayloadAction<LogAnalyticsPayload>): Generator<Effect, void, any> {
  try {
    const { eventType, screenName, dpocCaseId, stepInstruction, value } = action.payload;

    const caseDetail: CasePublicDto | undefined = yield select(getCaseDetail);

    const payload: AnalyticsDto = {
      sessionId: sessionService.getSessionId(),
      eventType,
      dpocCaseId: caseDetail?.dpocCaseId ?? dpocCaseId,
      submissionId: caseDetail?.submission.id,
      defectTypeGuideId: stepInstruction?.defectTypeGuideId,
      screenName,
      value,
    };

    yield call(fetchService.logAnalytics, payload);
    yield put(analyticsActions.logFinished());
  } catch {
    yield put(analyticsActions.logFailed());
  }
}

export function* watchCaseDetailSagas(): Generator<ForkEffect, void> {
  yield takeEvery(analyticsActions.log, watchAnalyticsLog);
}

const analyticsSagas = watchCaseDetailSagas;

export default analyticsSagas;
