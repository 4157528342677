import React, { type FC } from 'react';
import classNames from 'classnames';
import { type Instruction, type MediaLink } from '../../types/app';

import './InstructionMedia.scss';
import { useTranslation } from 'react-i18next';
import { LoadingMediaElement } from '../index';
import { BestPracticeEnum } from '../../types/dtos';

interface InstructionMediaProps {
  instruction: Instruction;
  mediaLink: MediaLink;
  showExampleLabel: boolean;
}

const InstructionMedia: FC<InstructionMediaProps> = ({ instruction, mediaLink, showExampleLabel }) => {
  const { t } = useTranslation();

  return (
    <div className="InstructionMedia">
      <LoadingMediaElement
        className="InstructionMedia__imageWrapper__image"
        mediaLink={mediaLink}
        isVideo={instruction.isVideo}
      />
      {showExampleLabel && (
        <div className="InstructionMedia__imageWrapper__exampleLabel">
          {instruction.isVideo ? t('Instructions_videoExample') : t('Instructions_imageExample')}
        </div>
      )}
      {instruction.text && (
        <div className="InstructionMedia__header">
          <div
            className={classNames('InstructionMedia__header__text', {
              'InstructionMedia__header__text--do': instruction.doDont === BestPracticeEnum.DO,
              'InstructionMedia__header__text--dont': instruction.doDont === BestPracticeEnum.DONT,
            })}
          >
            {instruction.text}
          </div>
        </div>
      )}
    </div>
  );
};

export default InstructionMedia;
