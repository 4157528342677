import { useAppDispatch, useAppSelector } from '../hooks';
import windowService from '../../services/windowService';
import { countryActions, getCountry, getCountryFetching, getCountryFetchingError } from './countrySlice';
import { type CountryLocaleDto } from '../../types/dtos';

export const useCountry: () => {
  country?: CountryLocaleDto;
  countryFetching: boolean;
} = () => {
  const dispatch = useAppDispatch();

  const country = useAppSelector(getCountry);
  const countryFetching = useAppSelector(getCountryFetching);
  const countryFetchingError = useAppSelector(getCountryFetchingError);

  if (!countryFetching && !country && !countryFetchingError) {
    const countryCode = windowService.getCountrySearchParam();
    if (countryCode) {
      dispatch(countryActions.fetchCountry({ countryCode }));
    }
  }

  return { country, countryFetching };
};
