import React, { type FC } from 'react';
import { Spin } from 'antd';

import './Loading.scss';
import { Trans, useTranslation } from 'react-i18next';

const Loading: FC = () => {
  // We need this here to force re-render of the component on language change.
  const _useTranslation = useTranslation();

  return (
    <div className="Loading">
      <div className="Loading__content">
        <Spin
          size="large"
          delay={100}
          // Need defaults, this component mey be used before translations are loaded.
          tip={<Trans i18nKey="Loader_description" defaults="Loading…<br/>Just a few more seconds." />}
        >
          <div />
        </Spin>
      </div>
    </div>
  );
};

export default Loading;
