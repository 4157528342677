import React, { type ReactElement, useMemo } from 'react';
import { type ANALYTICS_EVENT_TYPES } from '../../types/constants';
import { useAnalytics } from '../../redux/analytics/analyticsHooks';

interface AnalyticsProps {
  eventType: ANALYTICS_EVENT_TYPES;
  analyticsValue?: string;
}

const withAnalytics = <ComponentProps extends object>(
  Component: React.ComponentType<ComponentProps>,
  eventNames: string[]
): React.FC<ComponentProps & AnalyticsProps> => {
  // noinspection UnnecessaryLocalVariableJS
  const AnalyticsWrapper = ({ eventType, analyticsValue, ...props }: ComponentProps & AnalyticsProps): ReactElement => {
    const { log } = useAnalytics();

    const callbackProps = useMemo(() => {
      const handleEvent =
        (eventName: string) =>
        (...args: any[]): void => {
          log(eventType, analyticsValue);
          (props as any)[eventName]?.(...args);
        };

      return eventNames.reduce((acc: Record<string, any>, eventName) => {
        acc[eventName] = handleEvent(eventName);
        return acc;
      }, {});
    }, [analyticsValue, eventType, log, props]);

    return <Component {...(props as ComponentProps)} {...callbackProps} />;
  };

  return AnalyticsWrapper;
};

export default withAnalytics;
