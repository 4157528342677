import React, { type FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import notFound from '../../images/notFound.svg';

import './ErrorBoundaryContent.scss';
import { ApplicationTitle } from '../index';
import { APPLICATION_CONFIGURATION } from '../../types/applicationConfiguration';
import { useAppParams } from '../../redux/hooks';

const ErrorBoundaryContent: FC = () => {
  const { t } = useTranslation();
  const { caseId } = useAppParams();

  return (
    <div className="ErrorBoundaryContent">
      <ApplicationTitle />
      <img className="ErrorBoundaryContent__image" alt="Case not found." src={notFound} />
      <div className="ErrorBoundaryContent__header">{t('ErrorBoundary_header')}</div>
      <div className="ErrorBoundaryContent__description">
        <Trans
          i18nKey="ErrorBoundary_description"
          values={{ contactEmail: APPLICATION_CONFIGURATION.App_Error_Email }}
          components={{
            a: (
              <a
                href={
                  APPLICATION_CONFIGURATION?.App_Error_Email
                    ? `mailto:${APPLICATION_CONFIGURATION?.App_Error_Email}?subject=${t('Help_subject', {
                        caseId: caseId ?? '',
                      })}`
                    : ''
                }
              >
                &nbsp;
              </a>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default ErrorBoundaryContent;
