import { createHashRouter } from 'react-router-dom';
import React from 'react';
import {
  CaseEnterPage,
  InstructionsPage,
  Page,
  TermsAndConditionsPage,
  TicketInformationPage,
  UploadPage,
  SubmitPage,
  ConfirmationPage,
  CaseNotFoundPage,
} from './pages';
import { ErrorBoundary, ErrorBoundaryContent } from './components';
import { type APP_PAGES } from './types/constants';
import CountryPage from './pages/CountryPage';
import MultipleUnitsInfoPage from './pages/MultipleUnitsInfoPage';
import { APPLICATION_PAGES } from './types/app';

const router = createHashRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <Page />
      </ErrorBoundary>
    ),
    children: [
      { path: APPLICATION_PAGES.countryPage.baseRoute, element: <CountryPage /> },
      { path: APPLICATION_PAGES.landingPage.baseRoute, element: <CaseEnterPage /> },
      { path: APPLICATION_PAGES.caseNotFound.baseRoute, element: <CaseNotFoundPage /> },
      { path: APPLICATION_PAGES.termsAndConditions.baseRoute, element: <TermsAndConditionsPage /> },
      { path: APPLICATION_PAGES.instructions.baseRoute, element: <InstructionsPage /> },
      { path: APPLICATION_PAGES.multipleUnitsInfo.baseRoute, element: <MultipleUnitsInfoPage /> },
      { path: APPLICATION_PAGES.ticketInformation.baseRoute, element: <TicketInformationPage /> },
      { path: APPLICATION_PAGES.upload.baseRoute, element: <UploadPage /> },
      { path: APPLICATION_PAGES.submit.baseRoute, element: <SubmitPage /> },
      { path: APPLICATION_PAGES.confirmation.baseRoute, element: <ConfirmationPage /> },
    ],
    errorElement: <ErrorBoundaryContent />,
  },
]);

const formatRelativeRoute = (route: string): string => `/${route}`;

export const relativeRoutes: Record<APP_PAGES, string> = Object.fromEntries(
  Object.entries(APPLICATION_PAGES).map(([key, route]) => [key, formatRelativeRoute(route.baseRoute)])
) as Record<APP_PAGES, string>;

export const routes = {
  caseNotFound: () => relativeRoutes.caseNotFound,
  countryPage: () => relativeRoutes.countryPage,
  landingPage: () => relativeRoutes.landingPage,
  ticketInformation: (caseId: string) =>
    relativeRoutes.ticketInformation.replace(':caseId', encodeURIComponent(caseId)),
  termsAndConditions: (caseId: string) =>
    relativeRoutes.termsAndConditions.replace(':caseId', encodeURIComponent(caseId)),
  instructions: (caseId: string) => relativeRoutes.instructions.replace(':caseId', encodeURIComponent(caseId)),
  multipleUnitsInfo: (caseId: string) =>
    relativeRoutes.multipleUnitsInfo.replace(':caseId', encodeURIComponent(caseId)),
  upload: (caseId: string, stepNumber: number) =>
    relativeRoutes.upload.replace(':caseId', encodeURIComponent(caseId)).replace(':stepNumber', stepNumber.toString()),
  submit: (caseId: string) => relativeRoutes.submit.replace(':caseId', encodeURIComponent(caseId)),
  confirmation: (caseId: string) => relativeRoutes.confirmation.replace(':caseId', encodeURIComponent(caseId)),
};

export default router;
