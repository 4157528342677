import React, { type FC } from 'react';
import { Button } from 'antd';
import { LeftOutlined, QuestionOutlined } from '@ant-design/icons';

import Loading from '../common/Loading';
import HelpIntro from './HelpIntro';
import { useHelpButton, useHelpWizard } from '../../redux/help/helpHooks';
import FaqMenu from './FaqMenu';
import FaqDetail from './FaqDetail';
import ReportedMedia from './ReportedMedia';
import AppModal from '../common/AppModal';

import './HelpButton.scss';

const HelpButton: FC = () => {
  const { faqList, isOpen, fetching, helpAllowed, open, close } = useHelpButton();
  const { currentStep, selectFaqItem, isReportingMedia, back, showFaq, selectedFaqItem, reportMedia } =
    useHelpWizard(isOpen);

  if (!helpAllowed) {
    return null;
  }

  return (
    <>
      <Button
        className="HelpButton"
        type="primary"
        shape="circle"
        size="small"
        icon={<QuestionOutlined />}
        onClick={open}
      />
      <AppModal open={isOpen} className="HelpButtonModal" onCancel={close} footer={null} width="100%">
        {back && (
          <Button type="text" size="small" onClick={back}>
            <LeftOutlined className="HelpButtonModal__back" />
          </Button>
        )}
        <div className="HelpButtonModal__content">
          {fetching && <Loading />}
          {!fetching && currentStep === 'INTRO' && <HelpIntro onFaqClick={showFaq} />}
          {!fetching && faqList && currentStep === 'FAQ_LIST' && (
            <FaqMenu faqList={faqList} selectFaqItem={selectFaqItem} />
          )}
          {!fetching && selectedFaqItem && currentStep === 'FAQ_DETAIL' && (
            <FaqDetail faqItem={selectedFaqItem} reportMedia={reportMedia} isReportingMedia={isReportingMedia} />
          )}
          {!fetching && currentStep === 'MEDIA_REPORTED' && <ReportedMedia />}
        </div>
      </AppModal>
    </>
  );
};

export default HelpButton;
