import React, { type FC } from 'react';

import { AgreeTermsForm, Loading } from '../index';
import './TicketInformationBody.scss';
import { useCaseDetail } from '../../redux/caseDetail/caseDetailHooks';
import { useTranslation } from 'react-i18next';

const TicketInformationBody: FC = () => {
  const { t } = useTranslation();
  const [caseDetail, _fetchCaseDetail, isFetching] = useCaseDetail();

  if (!caseDetail || isFetching) {
    return <Loading />;
  }

  return (
    <div className="TicketInformationBody">
      <div className="TicketInformationBody__content">{t('TicketInformation_instructions')}</div>
      <AgreeTermsForm caseDetail={caseDetail} />
      <div className="TicketInformationBody__timeout">{t('TicketInformation_timeout')}</div>
    </div>
  );
};

export default TicketInformationBody;
