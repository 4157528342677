import React, { type FC, useRef } from 'react';

import './MediaElement.scss';
import classNames from 'classnames';

interface MediaElementProps {
  className?: string;
  link: string;
  isVideo?: boolean;
}

const MediaElement: FC<MediaElementProps> = ({ className, link, isVideo }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  if (videoRef.current && isVideo) {
    videoRef.current.load();
  }

  return isVideo ? (
    <video className={classNames('MediaElement', className)} ref={videoRef} autoPlay playsInline loop muted>
      <source src={link} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  ) : (
    <img className={classNames('MediaElement', className)} src={link} alt="" sizes="" />
  );
};

export default MediaElement;
