import React, { type FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { type FaqPageDto } from '../../types/dtos';

import './FaqDetail.scss';
import MailToDpoc from './MailToDpoc';
import FaqHelpful from './FaqHelpful';
import { Button } from 'antd';

interface FaqDetailProps {
  faqItem: FaqPageDto;
  isReportingMedia: boolean;
  reportMedia: () => void;
}

const FaqDetail: FC<FaqDetailProps> = ({ faqItem, reportMedia, isReportingMedia }) => {
  const { t } = useTranslation();

  return (
    <div className="FaqDetail">
      <div className="FaqDetail__title">{t(faqItem.titleKey)}</div>
      <div className="FaqDetail__description">
        <Trans
          i18nKey={faqItem.textKey}
          components={{
            b: <strong />,
            ul: <ul />,
            li: <li />,
          }}
        />
      </div>
      {faqItem.canSendReport && (
        <Button className="FaqDetail__report" type="primary" loading={isReportingMedia} onClick={reportMedia}>
          {t('faq-report')}
        </Button>
      )}
      <div className="FaqDetail__divider" />
      <MailToDpoc />
      <FaqHelpful faqItem={faqItem} />
    </div>
  );
};

export default FaqDetail;
