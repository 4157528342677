import React, { type FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import classNames from 'classnames';

import { APPLICATION_CONFIGURATION } from '../types/applicationConfiguration';
import Language from '../components/common/Language';
import { HelpButton, SwitchToMobileButton } from '../components';
import {
  useEnsureRequireSubmissionStarted,
  useEnsureSubmissionNotFinished,
  usePassiveGetCaseDetail,
} from '../redux/caseDetail/caseDetailHooks';
import ErrorBoundary from '../components/common/ErrorBoundary';
import { useApplicationInit } from '../redux/hooks';
import { useCountry } from '../redux/country/countryHooks';
import { useEnsureCountryNotSelected, useEnsureCountrySelected } from '../redux/countrySelection/countrySelectionHooks';
import localizationService from '../services/localizationService';

import msdLogo from '../images/msdLogo.png';
import merckLogo from '../images/merckLogo.png';

import './Page.scss';

const Page: FC = () => {
  const [caseDetail] = usePassiveGetCaseDetail();
  useEnsureRequireSubmissionStarted();
  useEnsureSubmissionNotFinished();
  useEnsureCountrySelected();
  useEnsureCountryNotSelected();
  const applicationInit = useApplicationInit();
  const { country } = useCountry();

  const merckCountry = caseDetail ? caseDetail.merckCountry : country?.merckCountry;
  const direction = localizationService.getLanguageDirection();

  useEffect(() => {
    applicationInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigProvider direction={direction}>
      <div className={classNames('Page', { rtl: direction === 'rtl' })}>
        <header className="Page__header">
          <div className="Page__header__title">
            {!merckCountry && (
              <img className="Page__header__title__anthemLogo" src={msdLogo} alt="MSD Inventing for Life" />
            )}
            {merckCountry && (
              <img className="Page__header__title__anthemLogo" src={merckLogo} alt="Merck Inventing for Life" />
            )}
            {APPLICATION_CONFIGURATION.Display_Application_Version && (
              <div className="Page__header__title__appVersion">
                ({}
                {APPLICATION_CONFIGURATION.Display_Application_Version})
              </div>
            )}
          </div>
          <div className="Page__header__controls">
            <HelpButton />
            <SwitchToMobileButton />
            <Language />
          </div>
        </header>

        <div className="Page__content">
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Page;
