import React, { type FC } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { AnalyticsLinkWithQuery, FooterButtons, Loading } from '../components';

import { useCaseDetailFromQuery } from '../redux/caseDetail/caseDetailHooks';
import { routes } from '../router';
import InstructionsContent from '../components/Instructions/InstructionsContent';
import TicketInformation from '../components/TicketInformation/TicketInformation';
import windowService from '../services/windowService';
import { useTranslation } from 'react-i18next';
import { ANALYTICS_EVENT_TYPES } from '../types/constants';
import { useLanguage } from '../redux/language/languageHooks';

const InstructionsPage: FC = () => {
  const { t } = useTranslation();
  const [caseDetail, isFetching] = useCaseDetailFromQuery();
  const { selectedLocale } = useLanguage();

  if (isFetching || !caseDetail) {
    return <Loading />;
  }

  const bestPractices = caseDetail.submission.bestPractices[selectedLocale.localeCode] || [];

  return (
    <div className="InstructionsPage">
      {windowService.getIsDesktopScreen() && <TicketInformation caseDetail={caseDetail} />}
      <InstructionsContent bestPractices={bestPractices} />

      <FooterButtons>
        <AnalyticsLinkWithQuery
          eventType={ANALYTICS_EVENT_TYPES.Back}
          type="primary"
          to={routes.ticketInformation(caseDetail.dpocCaseId)}
        >
          <LeftOutlined /> {t('Navigation_back')}
        </AnalyticsLinkWithQuery>
        <AnalyticsLinkWithQuery
          eventType={ANALYTICS_EVENT_TYPES.Next}
          type="primary"
          to={
            caseDetail.multipleUnits
              ? routes.multipleUnitsInfo(caseDetail.dpocCaseId)
              : routes.upload(caseDetail.dpocCaseId, 1)
          }
        >
          {t('Navigation_next')} <RightOutlined />
        </AnalyticsLinkWithQuery>
      </FooterButtons>
    </div>
  );
};

export default InstructionsPage;
