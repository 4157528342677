import { type NavigateFunction } from 'react-router';
import { createSlice, type Draft, type PayloadAction } from '@reduxjs/toolkit';

import { type MediaData } from '../../types/app';
import { type RootState } from '../store';
import { caseDetailActions } from '../caseDetail/caseDetailSlice';
import { type MediaTypes } from '../../types/constants';

export interface UploadSlice {
  isSubmitting: boolean;
  media: Record<number, MediaData[]>; // [key: stepNumber]
}

export const initialState: UploadSlice = {
  isSubmitting: false,
  media: {},
};

export const getIsSubmitting = (state: RootState): boolean => state.upload.isSubmitting;
export const getMedia = (state: RootState): Record<number, MediaData[]> => state.upload.media;

export interface UploadFilePayload {
  stepNumber: number;
  content: string;
  mediaType: MediaTypes;
  file: File;
}

export interface UploadMediaFinishedPayload {
  stepNumber: number;
  file: File;
  url: string;
  s3Key: string;
}

export interface UploadMediaFailedPayload {
  stepNumber: number;
  file: File;
}

export interface DeleteMediaPayload {
  stepNumber: number;
  s3Key: string;
  mediaIndex: number;
  mediaType: MediaTypes;
}

const resetUploadSlice = (state: Draft<UploadSlice>): void => {
  Object.keys(initialState).forEach((key) => {
    // @ts-expect-error Reset all properties to default state.
    state[key] = initialState[key];
  });
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    uploadFile: (state, action: PayloadAction<UploadFilePayload>) => {
      const { stepNumber, content, mediaType, file } = action.payload;
      state.media[stepNumber] = state.media[stepNumber] || [];
      state.media[stepNumber].push({
        uploaded: false,
        uploading: true,
        content,
        type: mediaType,
        file,
      });
    },
    uploadFileFinished: (state, action: PayloadAction<UploadMediaFinishedPayload>) => {
      const { stepNumber, file, url, s3Key } = action.payload;

      state.media[stepNumber]?.forEach((stateMedia) => {
        if (file === stateMedia.file) {
          stateMedia.uploaded = true;
          stateMedia.uploading = false;
          stateMedia.url = url;
          stateMedia.s3Key = s3Key;
        }
      });
    },
    uploadFileFailed: (state, action: PayloadAction<UploadMediaFailedPayload>) => {
      const { stepNumber, file } = action.payload;

      state.media[stepNumber]?.forEach((stateMedia) => {
        if (file === stateMedia.file) {
          stateMedia.uploaded = false;
          stateMedia.uploading = false;
        }
      });
    },
    deleteMedia: (state, action: PayloadAction<DeleteMediaPayload>) => {
      const { stepNumber, mediaIndex } = action.payload;
      state.media[stepNumber].splice(mediaIndex, 1);
    },
    deleteMediaFinished: () => {},
    deleteMediaFailed: () => {},
    submit: (state, _action: PayloadAction<NavigateFunction>) => {
      state.isSubmitting = true;
    },
    submitFinished: (state) => {
      state.isSubmitting = false;
    },
    submitFailed: (state) => {
      state.isSubmitting = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(caseDetailActions.reset, resetUploadSlice);
    builder.addCase(caseDetailActions.fetchDetail, resetUploadSlice);
    builder.addCase(caseDetailActions.startSubmission, resetUploadSlice);
  },
});

export const { actions: uploadActions, reducer: uploadReducer } = uploadSlice;
