import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '../store';

export enum MediaFetchingStates {
  INIT = 'INIT',
  LOADING_ALREADY = 'LOADING_ALREADY',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

export interface MediaData {
  data?: string;
  state: MediaFetchingStates;
}

export interface MediaCacheSlice {
  media: Record<string, MediaData>;
}

export const mediaCacheInitialState: MediaCacheSlice = {
  media: {},
};

export const getMedia = (state: RootState): Record<string, MediaData> => state.mediaCache.media;

export interface RequestMediaPayload {
  src: string;
  mediaKey: string;
}

export interface MediaLoadedPayload {
  src: string;
  mediaKey: string;
  data: string;
}

export interface MediaLoadFailedPayload {
  src: string;
  mediaKey: string;
}

export const mediaCacheSlice = createSlice({
  name: 'mediaCache',
  initialState: mediaCacheInitialState,
  reducers: {
    request: (state, { payload: { mediaKey } }: PayloadAction<RequestMediaPayload>) => {
      if (!state.media[mediaKey]) {
        state.media[mediaKey] = { state: MediaFetchingStates.INIT };
      } else if (state.media[mediaKey].state === MediaFetchingStates.INIT) {
        state.media[mediaKey].state = MediaFetchingStates.LOADING_ALREADY;
      }
    },
    loaded: (state, { payload: { mediaKey, data } }: PayloadAction<MediaLoadedPayload>) => {
      state.media[mediaKey].data = data;
      state.media[mediaKey].state = MediaFetchingStates.LOADED;
    },
    loadFailed: (state, { payload: { mediaKey } }: PayloadAction<MediaLoadFailedPayload>) => {
      state.media[mediaKey].state = MediaFetchingStates.ERROR;
    },
  },
});

export const { actions: mediaCacheActions, reducer: mediaCacheReducer } = mediaCacheSlice;
