import { type CasePublicDto, type StepInstructionPublicDto, type SubmissionPublicDto } from '../types/dtos';
import { type MediaLink } from '../types/app';

const getMediaKey = (
  submission: SubmissionPublicDto,
  stepInstruction: StepInstructionPublicDto,
  isVideo: boolean,
  index: number
): string => `${submission.id}-${stepInstruction.id}-${isVideo ? 'V' : 'P'}-${index.toString()}`;

const createMediaLink = (
  src: string,
  caseDetailDto: CasePublicDto,
  instruction: StepInstructionPublicDto,
  isVideo: boolean,
  index: number
): MediaLink => ({
  src,
  mediaKey: getMediaKey(caseDetailDto.submission, instruction, isVideo, index),
});

const mediaService = {
  getMediaKey,
  createMediaLink,
};

export default mediaService;
