import React, { type FC } from 'react';

import './CaseEnterHeader.scss';
import { Trans, useTranslation } from 'react-i18next';
import { ApplicationTitle } from '../index';

const CaseEnterHeader: FC = () => {
  // We need this here to force component rerender on language change.
  const _useTranslation = useTranslation();

  return (
    <div className="CaseEnterHeader">
      <ApplicationTitle />
      <div className="CaseEnterHeader__description">
        <div className="CaseEnterHeader__description__content">
          <Trans i18nKey="CaseEnter_description" />
        </div>
      </div>
    </div>
  );
};

export default CaseEnterHeader;
