import React, { type FC } from 'react';
import { Progress } from 'antd';
import { CAROUSEL_MAX_SCREEN_WIDTH } from '../../types/constants';

import './SubmissionProgress.scss';
import { useTranslation } from 'react-i18next';

interface SubmissionProgressProps {
  stepNumber: number;
  totalIndices: number;
}

const SubmissionProgress: FC<SubmissionProgressProps> = ({ stepNumber, totalIndices }) => {
  const { t } = useTranslation();

  return (
    <div className="SubmissionProgress">
      <Progress
        size={window.screen.width <= CAROUSEL_MAX_SCREEN_WIDTH ? 62 : 86}
        className="SubmissionProgress"
        type="circle"
        status="normal"
        percent={(stepNumber / totalIndices) * 100}
        format={() => `${stepNumber} / ${totalIndices}`}
      />
      <div className="StepHeader__progress__step">{t('Upload_step')}</div>
    </div>
  );
};

export default SubmissionProgress;
