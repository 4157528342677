import React, { type FC, type MediaHTMLAttributes, useRef } from 'react';
import { useMediaLoadingCache } from '../../redux/mediaCache/mediaCacheHook';
import { CenteredSpin } from '../index';

type LoadingVideoProps = MediaHTMLAttributes<HTMLVideoElement> & {
  src: string;
  mediaKey: string;
};

const LoadingVideo: FC<LoadingVideoProps> = ({ src, mediaKey, ...rest }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isLoading, data } = useMediaLoadingCache(src, mediaKey);

  if (videoRef.current) {
    videoRef.current.load();
  }

  if (isLoading) {
    return <CenteredSpin size="default" />;
  }

  return <video src={data} ref={videoRef} {...rest} />;
};

export default LoadingVideo;
