import { APPLICATION_CONFIGURATION } from '../types/applicationConfiguration';
import { type MediaTypes } from '../types/constants';

const URL_DOMAIN = APPLICATION_CONFIGURATION.API_URL ?? '';

const forceDomain = (urlBase: string): string => (urlBase ? `${URL_DOMAIN}${urlBase}` : urlBase);

const apiVersion = 'v1';
const apiPrefix = forceDomain(`/public/api/${apiVersion}`);

const baseUrls = {
  analytics: '/analytics',
  countries: '/countries',
  fetchLanguageSelectionTranslations: '/translations/language-selection',
  country: '/countries/{countryCode}',
  submissions: '/cases/{dpocCaseId}/submissions',
  submissionStart: '/cases/{dpocCaseId}/submissions/{submissionId}/start?localeCode={localeCode}',
  uploadMediaDetails:
    '/cases/{dpocCaseId}/submissions/{submissionId}/steps/{stepNumber}/media?photoOrVideo={photoOrVideo}&fileExtension={fileExtension}',
  deleteMedia:
    '/cases/{dpocCaseId}/submissions/{submissionId}/steps/{stepNumber}/media?photoOrVideo={photoOrVideo}&s3key={s3Key}',
  reportMedia: '/faq/report-media?dpocCaseId={dpocCaseId}',
  faq: '/faq?dpocCaseId={dpocCaseId}',
  submit: '/cases/{dpocCaseId}/submissions/{submissionId}',
  translationsByDpocCaseId: '/translations?dpocCaseId={dpocCaseId}',
  translationsByCountryCode: '/translations?countryCode={countryCode}',
};

const formatUrl = (urlBase: string): string => `${apiPrefix}${urlBase}`;

const prefixedUrls = Object.fromEntries(
  Object.entries(baseUrls).map(([key, urlBase]) => [key, formatUrl(urlBase)])
) as typeof baseUrls;

const urlService = {
  forceDomain,
  analytics: () => prefixedUrls.analytics,
  countries: () => prefixedUrls.countries,
  fetchLanguageSelectionTranslations: () => prefixedUrls.fetchLanguageSelectionTranslations,
  country: (countryCode: string) => prefixedUrls.country.replace('{countryCode}', countryCode),
  submissions: (caseId: string) => prefixedUrls.submissions.replace('{dpocCaseId}', encodeURIComponent(caseId)),
  submissionStart: (caseId: string, submissionId: string, localeCode: string) =>
    prefixedUrls.submissionStart
      .replace('{dpocCaseId}', encodeURIComponent(caseId))
      .replace('{submissionId}', submissionId)
      .replace('{localeCode}', localeCode),
  uploadMediaDetails: (
    caseId: string,
    submissionId: string,
    stepNumber: number,
    mediaType: MediaTypes,
    fileExtension: string
  ) =>
    prefixedUrls.uploadMediaDetails
      .replace('{dpocCaseId}', encodeURIComponent(caseId))
      .replace('{submissionId}', submissionId)
      .replace('{stepNumber}', stepNumber.toString())
      .replace('{photoOrVideo}', mediaType.toString())
      .replace('{fileExtension}', fileExtension),
  deleteMedia: (caseId: string, submissionId: string, stepNumber: number, mediaType: MediaTypes, s3Key: string) =>
    prefixedUrls.deleteMedia
      .replace('{dpocCaseId}', encodeURIComponent(caseId))
      .replace('{submissionId}', submissionId)
      .replace('{stepNumber}', stepNumber.toString())
      .replace('{photoOrVideo}', mediaType.toString())
      .replace('{s3Key}', s3Key),
  reportMedia: (dpocCaseId: string) => prefixedUrls.reportMedia.replace('{dpocCaseId}', encodeURIComponent(dpocCaseId)),
  faq: (dpocCaseId: string) => prefixedUrls.faq.replace('{dpocCaseId}', encodeURIComponent(dpocCaseId)),
  submit: (caseId: string, submissionId: string) =>
    prefixedUrls.submit.replace('{dpocCaseId}', encodeURIComponent(caseId)).replace('{submissionId}', submissionId),
  translationsByDpocCaseId: (dpocCaseId: string) =>
    prefixedUrls.translationsByDpocCaseId.replace('{dpocCaseId}', encodeURIComponent(dpocCaseId)),
  translationsByCountryCode: (countryCode: string) =>
    prefixedUrls.translationsByCountryCode.replace('{countryCode}', encodeURIComponent(countryCode)),
};

export default urlService;
