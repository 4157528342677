import React, { type FC } from 'react';
// eslint-disable-next-line no-restricted-imports
import { ConfigProvider, Popover, type PopoverProps } from 'antd';
import classNames from 'classnames';
import localizationService from '../../services/localizationService';

interface AppAppPopoverProps extends PopoverProps {}

const AppPopover: FC<AppAppPopoverProps> = ({ className, title, ...restProps }) => {
  const direction = localizationService.getLanguageDirection();
  return (
    <Popover
      title={(...args) => (
        <ConfigProvider direction={direction}>
          <div className={classNames('PopoverTitle', className, { rtl: direction === 'rtl' })}>
            {typeof title === 'function' ? title(...args) : title}
          </div>
        </ConfigProvider>
      )}
      {...restProps}
    />
  );
};

export default AppPopover;
