import React, { type DragEvent, type FC, useCallback, useMemo } from 'react';
import { Button, notification, Upload } from 'antd';

import { useGetStepMedia, useUploadFile } from '../../redux/upload/uploadHooks';
import {
  ACCEPT_IMAGE_EXTENSIONS,
  ACCEPT_VIDEO_EXTENSIONS,
  ANALYTICS_EVENT_TYPES,
  MAX_UPLOAD_PHOTO_LIMIT,
  MAX_UPLOAD_VIDEO_LIMIT,
  MediaTypes,
} from '../../types/constants';
import windowService from '../../services/windowService';
import MediaDragger from './MediaDragger';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '../../redux/analytics/analyticsHooks';
import { type RcFile, type UploadChangeParam } from 'antd/es/upload/interface';
import { CameraFilled, VideoCameraFilled } from '@ant-design/icons';

interface UploadButtonProps {
  mediaType: MediaTypes;
  stepNumber: number;
}

export const UPLOAD_CONFIGURATIONS = {
  [MediaTypes.IMAGE]: {
    acceptExtensions: ACCEPT_IMAGE_EXTENSIONS,
    limit: MAX_UPLOAD_PHOTO_LIMIT,
    icon: CameraFilled,
    uploadButtonText: 'Upload_addPhoto',
    draggerText: 'Upload_dragAndDropPhotoTitle',
    limitErrorMessage: 'Upload_photosLimitExceeded',
    limitErrorDescription: 'Upload_photosLimitExceededDescription',
    invalidTypeErrorMessage: 'Upload_invalidImageType',
    invalidTypeErrorDescription: 'Upload_invalidImageTypeDescription',
    addMediaAnalyticsEventType: ANALYTICS_EVENT_TYPES.AddPhoto,
    addMediaAnalyticsValue: 'photo',
  },
  [MediaTypes.VIDEO]: {
    acceptExtensions: ACCEPT_VIDEO_EXTENSIONS,
    limit: MAX_UPLOAD_VIDEO_LIMIT,
    icon: VideoCameraFilled,
    uploadButtonText: 'Upload_addVideo',
    draggerText: 'Upload_dragAndDropVideoTitle',
    limitErrorMessage: 'Upload_videosLimitExceeded',
    limitErrorDescription: 'Upload_videosLimitExceededDescription',
    invalidTypeErrorMessage: 'Upload_invalidVideoType',
    invalidTypeErrorDescription: 'Upload_invalidVideoTypeDescription',
    addMediaAnalyticsEventType: ANALYTICS_EVENT_TYPES.AddVideo,
    addMediaAnalyticsValue: 'video',
  },
};

const UploadButton: FC<UploadButtonProps> = ({ mediaType, stepNumber }) => {
  const { t } = useTranslation();

  const draggedFiles = useMemo<string[]>(() => [], []);
  const uploadedFiles = useMemo<string[]>(() => [], []);
  const { log } = useAnalytics();

  const uploadFile = useUploadFile(stepNumber, mediaType);
  const stepMedia = useGetStepMedia(stepNumber);

  const isLimitReached = useCallback(
    (mediaType: MediaTypes): boolean => {
      const mediaCount = stepMedia.reduce((acc, { type }) => (type === mediaType ? acc + 1 : acc), 0);

      return UPLOAD_CONFIGURATIONS[mediaType].limit <= mediaCount;
    },
    [stepMedia]
  );

  const getHandleFileOnChange: (mediaType: MediaTypes) => (file: RcFile, fileList: File[]) => void = useCallback(() => {
    const handler: (file: RcFile, fileList: File[]) => void = (file, fileList) => {
      const { invalidTypeErrorMessage, invalidTypeErrorDescription, limit, limitErrorMessage, limitErrorDescription } =
        UPLOAD_CONFIGURATIONS[mediaType];
      const fileExt = file.name.split('.').pop() ?? '';
      if (!UPLOAD_CONFIGURATIONS[mediaType].acceptExtensions.includes(`.${fileExt}`)) {
        log(ANALYTICS_EVENT_TYPES.InvalidFile, UPLOAD_CONFIGURATIONS[mediaType].addMediaAnalyticsValue);
        notification.error({
          message: t(invalidTypeErrorMessage),
          description: t(invalidTypeErrorDescription),
          duration: 0,
        });

        return false;
      }

      const alreadySelectedMedia = stepMedia.filter(({ type }) => type === mediaType);
      if (fileList.length + alreadySelectedMedia.length > limit) {
        log(ANALYTICS_EVENT_TYPES.MediaLimitReached, UPLOAD_CONFIGURATIONS[mediaType].addMediaAnalyticsValue);
        notification.error({
          key: limitErrorMessage,
          message: t(limitErrorMessage),
          description: t(limitErrorDescription, { count: limit }),
          duration: 0,
        });

        return false;
      }
      uploadedFiles.push(file.uid);
      uploadFile(file);
      return false;
    };

    return handler;
  }, [mediaType, stepMedia, uploadedFiles, uploadFile, log, t]);

  const handleChange = (e: UploadChangeParam): void => {
    const analyticsValue = draggedFiles.includes(e.file.uid) ? 'drag&drop' : 'click';
    const fileUploaded = uploadedFiles.includes(e.file.uid);

    if (fileUploaded) {
      log(UPLOAD_CONFIGURATIONS[mediaType].addMediaAnalyticsEventType, analyticsValue);
    }
  };

  const handleOnDrop = (e: DragEvent<HTMLDivElement>): void => {
    const fileUIDs: string[] = [];
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      const file = e.dataTransfer.files[i] as RcFile;
      fileUIDs.push(file.uid);
    }
    draggedFiles.push(...fileUIDs);
  };

  const accept = useMemo(() => UPLOAD_CONFIGURATIONS[mediaType].acceptExtensions.join(', '), [mediaType]);
  const Icon = UPLOAD_CONFIGURATIONS[mediaType].icon;

  return !windowService.getIsTouchEnabled() ? (
    <MediaDragger
      accept={accept}
      beforeUpload={getHandleFileOnChange(mediaType)}
      onChange={handleChange}
      onDrop={handleOnDrop}
      showUploadList={false}
      disabled={isLimitReached(mediaType)}
      multiple={true}
    >
      {t(UPLOAD_CONFIGURATIONS[mediaType].draggerText)}
    </MediaDragger>
  ) : (
    <Upload
      accept={accept}
      beforeUpload={getHandleFileOnChange(mediaType)}
      onChange={handleChange}
      onDrop={handleOnDrop}
      showUploadList={false}
      multiple={true}
    >
      <Button type="primary" icon={<Icon />} disabled={isLimitReached(mediaType)}>
        {t(UPLOAD_CONFIGURATIONS[mediaType].uploadButtonText)}
      </Button>
    </Upload>
  );
};
export default UploadButton;
