import { call, type Effect, type ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { type FetchTranslationsPayload, languageActions } from './languageSlice';
import { type PayloadAction } from '@reduxjs/toolkit';
import fetchService, { type FetchError } from '../../services/fetchService';
import localizationService from '../../services/localizationService';
import { notification } from 'antd';

export function* functionDoFetchTranslations(countryCode?: string, dpocCaseId?: string): Generator<Effect, void, any> {
  let translations;
  if (dpocCaseId) {
    translations = yield call(fetchService.fetchTranslationsByDpocCaseId, dpocCaseId);
  } else if (countryCode) {
    translations = yield call(fetchService.fetchTranslationsByCountryCode, countryCode);
  }
  yield call(localizationService.addTranslations, translations);
}

function* watchFetchTranslations(action: PayloadAction<FetchTranslationsPayload>): Generator<Effect, void, any> {
  try {
    yield call(functionDoFetchTranslations, action.payload.countryCode);
    yield put(languageActions.fetchTranslationsFinished());
  } catch (e) {
    if ((e as FetchError).status === 404) {
      notification.error({
        message: 'Country not found',
        description: `Unable to load localization package for country with code '${action.payload.countryCode ?? ''}'`,
      });
    }
    yield put(languageActions.fetchTranslationsFailed());
  }
}

export function* languageSagas(): Generator<ForkEffect, void> {
  yield takeEvery(languageActions.fetchTranslations, watchFetchTranslations);
}

export default languageSagas;
