import React, { type FC } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';

import './SelectionTooltip.scss';
import { useTranslation } from 'react-i18next';

const SelectionTooltip: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="SelectionTooltip">
      <InfoCircleFilled className="SelectionTooltip__icon" />
      <div className="SelectionTooltip__content">{t('LanguageSelection_selectLocationInfo_tooltip')}</div>
    </div>
  );
};

export default SelectionTooltip;
