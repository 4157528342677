import { notification } from 'antd';

import urlsService from './urlsService';
import {
  type AnalyticsDto,
  type CasePublicDto,
  type CountryLocaleDto,
  type FaqWithTranslationsDto,
  type LanguageSelectionTranslationsDto,
  type MediaPutTuple,
  type PublicTranslationDto,
} from '../types/dtos';
import { APPLICATION_CONFIGURATION } from '../types/applicationConfiguration';
import { MEDIA_RE_FETCH_DELAY, MEDIA_RE_FETCH_RETRY_COUNT, type MediaTypes } from '../types/constants';

export class FetchError extends Error {
  status: number;

  constructor(msg: string, status: number) {
    super(msg);
    this.status = status;
  }
}

interface ErrorResponse {
  errorMessage?: string;
  additionalDetails?: Record<string, string>;
}

const handleError = async (response: Response, url: string, init?: RequestInit): Promise<string> =>
  await response
    .text()
    .then((errorMessage): ErrorResponse => {
      try {
        // usually {errorMessage: ...} from backend, but can be HTML from server/framework unhandled exceptions
        return JSON.parse(errorMessage);
      } catch (err) {
        console.error(url, err);

        return { errorMessage };
      }
    })
    .then((error) => {
      const errorMessage = error.errorMessage != null ? error.errorMessage : JSON.stringify(error, null, 2);
      const method = init?.method ?? 'GET';
      const { status, statusText } = response;
      const intro = `${status} ${statusText}: ${method} ${url}`;
      if (error.additionalDetails) {
        console.error(`${intro} additional details\n`, error.additionalDetails);
      }

      if (response.status !== 404 && response.status !== 410) {
        notification.error({
          message: 'Network Error',
          description: errorMessage,
          duration: 0,
        });
      }

      throw new FetchError(`${intro}\n${errorMessage}`, status);
    });

async function fetchWithRetries(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  let count = MEDIA_RE_FETCH_RETRY_COUNT;
  while (count > 0) {
    try {
      return await fetch(input, init);
    } catch (error) {}

    await new Promise((resolve) => setTimeout(resolve, MEDIA_RE_FETCH_DELAY));
    count -= 1;
  }

  throw new Error(`Unable to get data even after ${MEDIA_RE_FETCH_RETRY_COUNT} retries.`);
}

const doFetchAwsBlob = async (awsUrl: string): Promise<string> =>
  await fetchWithRetries(awsUrl).then(async (response) => {
    if (response.ok) {
      return await response.blob().then(async (blob) => URL.createObjectURL(blob));
    } else {
      return await handleError(response, awsUrl);
    }
  });

const doFetch = async <T>(url: string, init?: RequestInit): Promise<T> =>
  await fetch(url, {
    redirect: 'manual',
    ...init,
    headers: {
      ...(!!APPLICATION_CONFIGURATION.X_Merck_APIKey && {
        'X-Merck-APIKey': APPLICATION_CONFIGURATION.X_Merck_APIKey,
      }),
      ...(init?.body && typeof init.body === 'string' && { 'Content-Type': 'application/json' }),
      ...init?.headers,
    },
  }).then(async (response) => {
    if (response.ok) {
      return await response.text().then((text) => {
        try {
          // allowed empty content from backend or JSON
          return text ? JSON.parse(text) : undefined;
        } catch (err) {
          console.error(url, err);

          return { text };
        }
      });
    } else {
      await handleError(response, url, init);
    }
  });

const fetchJson = async <T>(url: string): Promise<T> =>
  await doFetch(url, {
    method: 'GET',
  });

const putFileNoAuth = async (url: string, file: File, init?: RequestInit): Promise<string | Response> => {
  const blob = new Blob([file]);

  return await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      'Content-Disposition': `attachment; filename="${file.name}"`,
    },
    body: blob,
    ...init,
  }).then((response) => response);
};

const deleteJson = async <T>(url: string, body?: any): Promise<T> =>
  await doFetch(url, {
    method: 'DELETE',
    body: body && JSON.stringify(body),
  });

const postJSON = async <T>(url: string, body?: any): Promise<T> => {
  return await doFetch(url, {
    method: 'POST',
    body: body && JSON.stringify(body),
  });
};

const putJson = async <T>(url: string, body?: any): Promise<T> =>
  await doFetch(url, {
    method: 'PUT',
    body: body && JSON.stringify(body),
  });

const fetchMedia = async (awsUrl: string): Promise<any> => await doFetchAwsBlob(awsUrl);

const logAnalytics = async (body: AnalyticsDto): Promise<void> => {
  await postJSON(urlsService.analytics(), body);
};

const fetchCountries = async (): Promise<CountryLocaleDto[]> => await fetchJson(urlsService.countries());

const fetchLanguageSelectionTranslations = async (): Promise<LanguageSelectionTranslationsDto> =>
  await fetchJson(urlsService.fetchLanguageSelectionTranslations());

const fetchCountry = async (countryCode: string): Promise<CountryLocaleDto> =>
  await fetchJson(urlsService.country(countryCode));

const createNewSubmission = async (caseId: string): Promise<CasePublicDto> =>
  await postJSON(urlsService.submissions(caseId));

const startSubmission = async (caseId: string, submissionId: string, localeCode: string): Promise<CasePublicDto> =>
  await postJSON(urlsService.submissionStart(caseId, submissionId, localeCode));

const getUploadMediaDetails = async (
  caseId: string,
  submissionId: string,
  stepNumber: number,
  mediaType: MediaTypes,
  fileExtension: string
): Promise<MediaPutTuple> =>
  await fetchJson(urlsService.uploadMediaDetails(caseId, submissionId, stepNumber, mediaType, fileExtension));

const deleteMedia = async (
  caseId: string,
  submissionId: string,
  stepNumber: number,
  mediaType: MediaTypes,
  s3Key: string
): Promise<void> => {
  await deleteJson(urlsService.deleteMedia(caseId, submissionId, stepNumber, mediaType, s3Key));
};

const reportMedia = async (caseId: string): Promise<void> => {
  await postJSON(urlsService.reportMedia(caseId));
};

const getFaq = async (caseId: string): Promise<FaqWithTranslationsDto> => await fetchJson(urlsService.faq(caseId));

const submit = async (caseId: string, submissionId: string): Promise<void> => {
  await putJson(urlsService.submit(caseId, submissionId));
};

const fetchTranslationsByDpocCaseId = async (dpocCaseId: string): Promise<PublicTranslationDto> =>
  await fetchJson(urlsService.translationsByDpocCaseId(dpocCaseId));

const fetchTranslationsByCountryCode = async (countryCode: string): Promise<PublicTranslationDto> =>
  await fetchJson(urlsService.translationsByCountryCode(countryCode));

const fetchService = {
  fetchCountries,
  fetchLanguageSelectionTranslations,
  fetchMedia,
  fetchCountry,
  putFileNoAuth,
  logAnalytics,
  createNewSubmission,
  startSubmission,
  getUploadMediaDetails,
  deleteMedia,
  reportMedia,
  getFaq,
  submit,
  fetchTranslationsByDpocCaseId,
  fetchTranslationsByCountryCode,
};

export default fetchService;
