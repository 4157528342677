import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import './ApplicationTitle.scss';

interface ApplicationTitleProps {
  titleCode?: string;
}

const ApplicationTitle: FC<ApplicationTitleProps> = ({ titleCode }) => {
  const { t } = useTranslation();
  return <h4 className="ApplicationTitle">{t(titleCode ?? 'Application_title')}</h4>;
};

export default ApplicationTitle;
