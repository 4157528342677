import React, { type FC } from 'react';
import { useCaseDetailFromQuery } from '../redux/caseDetail/caseDetailHooks';
import { ApplicationTitle, Loading, Policies, SwitchToMobile, TicketInformation } from '../components';
import TicketInformationBody from '../components/TicketInformation/TicketInformationBody';
import windowService from '../services/windowService';

const TicketInformationPage: FC = () => {
  const [caseDetail, isFetching] = useCaseDetailFromQuery();

  if (!caseDetail || isFetching) {
    return <Loading />;
  }

  return (
    <div className="TicketInformationPage">
      {windowService.getIsDesktopScreen() && <SwitchToMobile dpocCaseId={caseDetail.dpocCaseId} withBackground />}
      <ApplicationTitle />
      <TicketInformation caseDetail={caseDetail} />
      <TicketInformationBody />
      <Policies />
    </div>
  );
};

export default TicketInformationPage;
