import React, { type ButtonHTMLAttributes, type FC } from 'react';
import classNames from 'classnames';

import './ButtonLink.scss';

const ButtonLink: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, ...rest }) => (
  <button className={classNames('ButtonLink', className)} {...rest} />
);

export default ButtonLink;
