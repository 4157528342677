import React, { type FC } from 'react';
import { useCaseDetailFromQuery } from '../redux/caseDetail/caseDetailHooks';
import AgreeTermsForm from '../components/common/AgreeTermsForm';
import { Loading } from '../components';

import './TermsAndConditionsPage.scss';
import { Trans, useTranslation } from 'react-i18next';

const TermsAndConditionsPage: FC = () => {
  const { t } = useTranslation();
  const [caseDetail, isFetching] = useCaseDetailFromQuery();

  if (!caseDetail || isFetching) {
    return <Loading />;
  }

  return (
    <div className="TermsAndConditionsPage">
      <div className="TermsAndConditionsPage__header">{t('TermsAndConditions_header')}</div>
      <div className="TermsAndConditionsPage__info">
        <div className="TermsAndConditionsPage__info__content">{t('TermsAndConditions_info')}</div>
      </div>
      <div className="TermsAndConditionsPage__content">
        <Trans
          i18nKey="TermsAndConditions_content"
          components={{
            b: <strong />,
            // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
            a: <a />,
          }}
        />
      </div>
      <div className="TermsAndConditionsPage__description">{t('TermsAndConditions_description')}</div>

      <AgreeTermsForm />
    </div>
  );
};

export default TermsAndConditionsPage;
