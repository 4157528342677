import dayjs from 'dayjs';

const toDisplayDate = (date: string): string => dayjs(date).format('DD MMMM, YYYY');
const toDisplayDateTime = (date: string): string => dayjs(date).format('DD MMMM, YYYY h:mm A');

const formatService = {
  toDisplayDate,
  toDisplayDateTime,
};

export default formatService;
