import { call, type Effect, type ForkEffect, select, takeEvery, put, take, delay } from 'redux-saga/effects';
import { type NavigateFunction } from 'react-router';
import { type PayloadAction } from '@reduxjs/toolkit';

import { type DeleteMediaPayload, getMedia, uploadActions, type UploadFilePayload } from './uploadSlice';
import fetchService from '../../services/fetchService';
import { getCaseDetail } from '../caseDetail/caseDetailSlice';
import { type CasePublicDto, type MediaPutTuple } from '../../types/dtos';
import { type MediaData } from '../../types/app';
import { routes } from '../../router';
import { CONFIRMATION_PAGE_ID, type MediaTypes } from '../../types/constants';

function* doUploadMedia(
  caseDetail: CasePublicDto,
  stepNumber: number,
  file: File,
  mediaType: MediaTypes
): Generator<Effect, void, any> {
  try {
    const extension = file?.name?.split('.').pop() ?? '';

    const mediaTuple: MediaPutTuple = yield call(
      fetchService.getUploadMediaDetails,
      caseDetail.dpocCaseId,
      caseDetail.submission.id,
      stepNumber,
      mediaType,
      extension
    );

    yield call(fetchService.putFileNoAuth, mediaTuple.mediaPutUrl, file);

    yield put(
      uploadActions.uploadFileFinished({
        stepNumber,
        file,
        url: mediaTuple.mediaPutUrl,
        s3Key: mediaTuple.mediaS3Key,
      })
    );
  } catch {
    yield put(uploadActions.uploadFileFailed({ stepNumber, file }));
  }
}

function* watchUploadFile(action: PayloadAction<UploadFilePayload>): Generator<Effect, void, CasePublicDto> {
  const { stepNumber, mediaType, file } = action.payload;
  const caseDetail = yield select(getCaseDetail);

  yield call(doUploadMedia, caseDetail, stepNumber, file, mediaType);
}

function* watchDeleteMedia(action: PayloadAction<DeleteMediaPayload>): Generator<Effect, void, CasePublicDto> {
  try {
    const { stepNumber, s3Key, mediaType } = action.payload;
    const caseDetail = yield select(getCaseDetail);

    yield call(fetchService.deleteMedia, caseDetail.dpocCaseId, caseDetail.submission.id, stepNumber, mediaType, s3Key);
    yield put(uploadActions.deleteMediaFinished());
  } catch {
    yield put(uploadActions.deleteMediaFailed());
  }
}

function* watchSubmit(action: PayloadAction<NavigateFunction>): Generator<Effect, void, any> {
  const caseDetail: CasePublicDto = yield select(getCaseDetail);
  try {
    let media: Record<number, MediaData[]> = yield select(getMedia);

    while (Object.values(media).some((mediaArray) => mediaArray.some(({ uploading }) => uploading))) {
      yield take();
      media = yield select(getMedia);
    }

    yield call(fetchService.submit, caseDetail.dpocCaseId, caseDetail.submission.id);
    action.payload(routes.confirmation(caseDetail.dpocCaseId));

    yield delay(0);

    // PQCPIMS-648: On some phones, uploadActions.submitFinished() is dispatched before the page is re-rendered with Confirmation page.
    // That causes redirect to landing page, because we are still on Submit Page and submission is switched to finished  - submit page requires started not finished submission
    while (!window.document.getElementById(CONFIRMATION_PAGE_ID)) {
      yield delay(50);
    }

    yield put(uploadActions.submitFinished());
  } catch {
    yield put(uploadActions.submitFailed());
  }
}

export function* watchUploadSagas(): Generator<ForkEffect, void> {
  yield takeEvery(uploadActions.uploadFile, watchUploadFile);
  yield takeEvery(uploadActions.deleteMedia, watchDeleteMedia);
  yield takeEvery(uploadActions.submit, watchSubmit);
}

const uploadSagas = watchUploadSagas;

export default uploadSagas;
