import React, { type FC } from 'react';
import { PictureOutlined } from '@ant-design/icons';

import './MediaPlaceholder.scss';

const MediaPlaceholder: FC = () => (
  <div className="MediaPlaceholder">
    <PictureOutlined />
  </div>
);

export default MediaPlaceholder;
