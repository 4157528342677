import React, { type FC } from 'react';
import { ConfirmationHeader, Loading, TicketInformation } from '../components';
import { useCaseDetailFromQuery } from '../redux/caseDetail/caseDetailHooks';
import SubmittedMedia from '../components/Confirmation/SubmittedMedia';
import windowService from '../services/windowService';
import { CONFIRMATION_PAGE_ID } from '../types/constants';

const ConfirmationPage: FC = () => {
  const [caseDetail] = useCaseDetailFromQuery();

  if (!caseDetail) {
    return <Loading />;
  }
  return (
    <div id={CONFIRMATION_PAGE_ID} className="ConfirmationPage">
      {windowService.getIsDesktopScreen() && <TicketInformation caseDetail={caseDetail} />}
      <ConfirmationHeader />
      {!windowService.getIsDesktopScreen() && <TicketInformation caseDetail={caseDetail} />}
      <SubmittedMedia />
    </div>
  );
};

export default ConfirmationPage;
