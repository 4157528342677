import React, { type FC, useCallback } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { getTermsAgreed } from '../../redux/caseDetail/caseDetailSlice';
import { Form } from 'antd';
import { type TicketInformationForm } from '../../types/app';
import { useTranslation } from 'react-i18next';
import { useStartGuidedInstructions } from '../../redux/caseDetail/caseDetailHooks';
import TermsCheckbox from './TermsCheckbox';
import { RightOutlined } from '@ant-design/icons';
import { type CasePublicDto } from '../../types/dtos';

import './AgreeTermsForm.scss';
import { AnalyticsButton } from '../index';
import { ANALYTICS_EVENT_TYPES } from '../../types/constants';

interface AgreeTermsFormProps {
  caseDetail?: CasePublicDto;
}

const AgreeTermsForm: FC<AgreeTermsFormProps> = ({ caseDetail }) => {
  const termsAgreedInTicketInformation = useAppSelector(getTermsAgreed);

  const [form] = Form.useForm<TicketInformationForm>();
  const { t } = useTranslation();
  const { isSubmissionStarting, startSubmission } = useStartGuidedInstructions();

  const termsAgreed = Form.useWatch('termsAgreed', form);

  const handleFinish = useCallback(() => {
    startSubmission(termsAgreed);
  }, [startSubmission, termsAgreed]);

  const agreedValidator = async (_: any, value: boolean): Promise<void> => {
    value ? await Promise.resolve() : await Promise.reject(new Error(t<string>('CaseEnter_agreedRequired')));
  };

  return (
    <Form className="AgreeTermsForm" requiredMark={false} form={form} onFinish={handleFinish}>
      <div className="AgreeTermsForm__bottomLine">
        <Form.Item
          initialValue={termsAgreedInTicketInformation}
          name="termsAgreed"
          className="AgreeTermsForm__agreed"
          valuePropName="checked"
          rules={[
            {
              validator: agreedValidator,
              message: <div className="AgreeTermsForm__error">{t<string>('CaseEnter_agreedRequired')}</div>,
            },
          ]}
        >
          <TermsCheckbox dpocCaseId={caseDetail?.dpocCaseId} />
        </Form.Item>
        <Form.Item>
          <AnalyticsButton
            eventType={ANALYTICS_EVENT_TYPES.StartGuidedInstruction}
            type="primary"
            className="AgreeTermsForm__submit"
            htmlType="submit"
            loading={isSubmissionStarting}
            disabled={!termsAgreed}
          >
            {t('TicketInformation_start')}
            <RightOutlined className="AgreeTermsForm__submit__icon" />
          </AnalyticsButton>
        </Form.Item>
      </div>
    </Form>
  );
};

export default AgreeTermsForm;
