import React, { type FC } from 'react';
// eslint-disable-next-line no-restricted-imports
import { ConfigProvider, Modal, type ModalProps } from 'antd';
import classNames from 'classnames';
import localizationService from '../../services/localizationService';

interface AppModalProps extends ModalProps {}

const AppModal: FC<AppModalProps> = ({ className, ...restProps }) => {
  const direction = localizationService.getLanguageDirection();
  return (
    <ConfigProvider direction={direction}>
      <Modal className={classNames('AppModal', className, { rtl: direction === 'rtl' })} {...restProps} />
    </ConfigProvider>
  );
};

export default AppModal;
