import React, { type FC } from 'react';
import switchToMobile from '../../images/switchToMobile.svg';

import './SwitchToMobile.scss';
import { Trans, useTranslation } from 'react-i18next';
import { QRCode } from 'antd';
import classNames from 'classnames';
import { useLinkCreator } from '../../redux/hooks';

interface SwitchToMobileProps {
  dpocCaseId?: string;
  withBackground?: boolean;
}

const SwitchToMobile: FC<SwitchToMobileProps> = ({ dpocCaseId, withBackground }) => {
  const { t } = useTranslation();

  const { displayHref, qrHref } = useLinkCreator();

  return (
    <div className={classNames('SwitchToMobile', { 'SwitchToMobile--background': withBackground })}>
      <img className="SwitchToMobile__swithToMobile" src={switchToMobile} alt="" />
      <div className="SwitchToMobile__body">
        <div className="SwitchToMobile__body__header">{t('SwitchTo_toMobileHeader')}</div>
        <div className="SwitchToMobile__body__title">{t('Switch_toMobileInstructionsTitle')}</div>
        <ol>
          <li className="SwitchToMobile__body__step">{t('Switch_openBrowserMobile')}</li>
          <li className="SwitchToMobile__body__step">
            <Trans
              i18nKey="Switch_goToWebsite"
              values={{ url: displayHref }}
              components={{ span: <span className="SwitchToMobile__body__step__link" /> }}
            />
          </li>
          {!!dpocCaseId && (
            <li className="SwitchToMobile__body__step">
              <Trans
                i18nKey="Switch_enterCaseId"
                values={{ caseId: dpocCaseId }}
                components={{
                  span: <span className="HelpButtonModal__body__switch__instructions__steps__step__link" />,
                }}
              />
            </li>
          )}
        </ol>
      </div>
      <div className="SwitchToMobile__qr">
        <div className="SwitchToMobile__qr__code">
          <QRCode value={qrHref} size={215} />
        </div>
        <div className="SwitchToMobile__qr__title">{t('Switch_QRTitle')}</div>
      </div>
    </div>
  );
};

export default SwitchToMobile;
