import { useAppDispatch, useAppParams, useAppRouter } from '../hooks';
import { analyticsActions } from './analyticsSlice';
import { type ANALYTICS_EVENT_TYPES } from '../../types/constants';
import { useCallback } from 'react';
import { useLanguage } from '../language/languageHooks';
import { usePassiveGetCaseDetail } from '../caseDetail/caseDetailHooks';

type LogHandler = (eventType: ANALYTICS_EVENT_TYPES, value?: string) => void;

export const useAnalytics: () => {
  log: LogHandler;
} = () => {
  const dispatch = useAppDispatch();
  const { caseId, stepNumber } = useAppParams();
  const { selectedLocale } = useLanguage();
  const [caseDetail] = usePassiveGetCaseDetail();
  const { activePage } = useAppRouter();

  const log: LogHandler = useCallback(
    (eventType, value?): void => {
      const stepInstruction =
        stepNumber !== undefined
          ? caseDetail?.submission.stepInstructions[selectedLocale.localeCode][stepNumber - 1]
          : undefined;

      const screenName = activePage.getScreenName(stepNumber);

      dispatch(analyticsActions.log({ eventType, screenName, dpocCaseId: caseId, stepInstruction, value }));
    },
    [activePage, caseDetail?.submission.stepInstructions, caseId, dispatch, selectedLocale.localeCode, stepNumber]
  );

  return { log };
};
