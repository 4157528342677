import React, { type FC } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';

import './PolicyCard.scss';

interface PolicyCardProps {
  header: string;
  description: string | React.ReactNode;
}

const PolicyCard: FC<PolicyCardProps> = ({ header, description }) => (
  <div className="PolicyCard">
    <InfoCircleFilled className="PolicyCard__icon" />
    <div className="PolicyCard__content">
      <div className="PolicyCard__content__header">{header}</div>
      <div className="PolicyCard__content__description">{description}</div>
    </div>
  </div>
);

export default PolicyCard;
