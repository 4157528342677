// com.msd.pqcpims.api.dto.LocaleDto
export interface LocaleDto {
  localeCode: string;
  localeDisplayName: string;
}

// com.msd.pqcpims.api.dto.CountryLocaleDto
export interface CountryLocaleDto {
  countryCode: string;
  countryDisplayName: string;
  regionDisplayName: string;
  countryTranslationKey: string;
  regionTranslationKey: string;
  merckCountry: boolean;
  defaultLocaleCode: string;
  allowedLocales: LocaleDto[];
}

export interface LanguageSelectionTranslationsDto {
  locales: LocaleDto[];
  translations: PublicTranslationDto;
}

// com.msd.pqcpimps.api.dto.pub.StepInstructionPublicDto
export interface StepInstructionPublicDto {
  id: string;
  defectTypeGuideId: string;
  description: string;
  general: boolean;
  numPictureRequested: number;
  numVideoRequested: number;
  samplePhotoLinks: string[];
  sampleVideoLinks: string[];
  multipleUnitsStep: boolean;
}

// com.msd.pqcpimps.api.dto.pub.SubmissionPublicDto
export interface SubmissionPublicDto {
  id: string;
  stepInstructions: Record<string, StepInstructionPublicDto[]>;
  bestPractices: Record<string, BestPracticesDto[]>;
  resubmission: boolean; // TODO should not be needed in Photo App
}

// com.msd.pqcpimps.api.dto.pub.CasePublicDto
export interface CasePublicDto {
  dpocCaseId: string;
  defectTypes: Record<string, string[]>;
  multipleUnits: boolean;
  merckCountry: boolean;
  countryCode: string;
  defaultLocaleCode: LocaleDto['localeCode'];
  locales: LocaleDto[];
  submission: SubmissionPublicDto;
  createdAt: string;
}

export interface BestPracticesDto {
  instructions: BestPracticeInstructionDto[];
}

export interface BestPracticeInstructionDto {
  doDont: BestPracticeEnum;
  text: string;
  isVideo?: boolean; // Currently not on BE. All items instructions are photo instructions.
  mediaLinks: SimpleMediaLinkDto[];
}

export interface SimpleMediaLinkDto {
  s3key: string;
  url: string;
}

// com.msd.pqcpims.api.model.MediaPutTuple
export interface MediaPutTuple {
  mediaS3Key: string;
  mediaPutUrl: string;
}

export interface AnalyticsDto {
  sessionId: string;
  eventType: string;
  screenName?: string;
  dpocCaseId?: string;
  submissionId?: string;
  s3Key?: string; // used only on Photo Portal
  defectTypeGuideId?: string;
  value?: string;
}

export interface FaqPageDto {
  titleKey: string;
  textKey: string;
  canSendReport: boolean;
}

export interface FaqDto {
  titleKey: string;
  children: FaqPageDto[];
}

export interface FaqWithTranslationsDto {
  faqList: Array<FaqDto | FaqPageDto>; // Definition on BE is different, but response JSONs will go like this
  translations: PublicTranslationDto;
  dpocEmail: string;
}

export type PublicTranslationDto = Record<string, Record<string, string>>; // {[locale]: {[translationCode]: translation}}

export enum BestPracticeEnum {
  DO = 'DO',
  DONT = 'DONT',
}
