import React, { type FC } from 'react';

import MediaElement from '../common/MediaElement';
import { type MediaData } from '../../types/app';
import { DeleteOutlined } from '@ant-design/icons';
import { useDeleteImage } from '../../redux/upload/uploadHooks';
import { AnalyticsButton } from '../index';
import { ANALYTICS_EVENT_TYPES, MediaTypes } from '../../types/constants';

import './DeletableMediaElement.scss';

interface DeletableMediaElementProps {
  stepMedia: MediaData[];
  mediaIndex: number;
  stepNumber: number;
}

const DeletableMediaElement: FC<DeletableMediaElementProps> = ({ stepMedia, mediaIndex, stepNumber }) => {
  const deleteImage = useDeleteImage();
  const media = stepMedia[mediaIndex];

  return (
    <div className="DeletableMediaElement">
      <MediaElement
        className="DeletableMediaElement__image"
        link={media.content}
        isVideo={media.type === MediaTypes.VIDEO}
      />
      <div className="DeletableMediaElement__count">{`${mediaIndex + 1}/${stepMedia.length}`}</div>
      <AnalyticsButton
        eventType={ANALYTICS_EVENT_TYPES.RemoveMedia}
        analyticsValue={media.type === MediaTypes.VIDEO ? 'video' : 'photo'}
        className="DeletableMediaElement__delete"
        type="primary"
        icon={<DeleteOutlined />}
        shape="circle"
        loading={media.uploading}
        onClick={() => {
          // s3key is always set, when `uploading` of the media is finished
          deleteImage(stepNumber, mediaIndex, media.type, media.s3Key ?? '');
        }}
      />
    </div>
  );
};

export default DeletableMediaElement;
