import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { type FaqPageDto } from '../../types/dtos';

import './FaqHelpful.scss';
import { Button } from 'antd';
import { useHelpfulFaq } from '../../redux/help/helpHooks';

interface FaqHelpfulProps {
  faqItem: FaqPageDto;
}

const FaqHelpful: FC<FaqHelpfulProps> = ({ faqItem }) => {
  const { t } = useTranslation();
  const { enabled, sayYes, sayNo } = useHelpfulFaq(faqItem);

  return (
    <div className="FaqHelpful">
      <div className="FaqHelpful__title">{t('faq-was-faq-helpful')}</div>
      <div className="FaqHelpful__actions">
        <Button className="FaqHelpful__actions__action" size="large" onClick={sayYes} disabled={!enabled}>
          {t('faq-yes')}
        </Button>
        <Button className="FaqHelpful__actions__action" size="large" onClick={sayNo} disabled={!enabled}>
          {t('faq-no')}
        </Button>
      </div>
    </div>
  );
};

export default FaqHelpful;
