import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { type CasePublicDto } from '../../types/dtos';
import StepHeader from './StepHeader';
import InstructionGroup from '../common/InstructionGroup';
import { type Instruction } from '../../types/app';
import { useGetStepMedia } from '../../redux/upload/uploadHooks';
import UploadedMedia from './UploadedMedia';
import { MAX_UPLOAD_PHOTO_LIMIT, MAX_UPLOAD_VIDEO_LIMIT, MediaTypes } from '../../types/constants';
import UploadButton from './UploadButton';
import { useLanguage } from '../../redux/language/languageHooks';
import mediaService from '../../services/mediaService';
import MultipleUnitsDescription from './MultipleUnitsDescription';

import './UploadStep.scss';

interface UploadStepProps {
  caseDetail: CasePublicDto;
  stepNumber: number;
}

const UploadStep: FC<UploadStepProps> = ({ caseDetail, stepNumber }) => {
  const { t } = useTranslation();
  const stepMedia = useGetStepMedia(stepNumber);

  const photos = stepMedia.filter(({ type }) => type === MediaTypes.IMAGE);
  const videos = stepMedia.filter(({ type }) => type === MediaTypes.VIDEO);

  const { selectedLocale } = useLanguage();
  const stepInstruction = caseDetail.submission.stepInstructions[selectedLocale.localeCode][stepNumber - 1];
  const totalIndices = caseDetail.submission.stepInstructions[selectedLocale.localeCode].length;
  const instructions: Instruction[] = [
    ...stepInstruction.samplePhotoLinks.map((photoLink, index) => ({
      mediaLinks: [mediaService.createMediaLink(photoLink, caseDetail, stepInstruction, false, index)],
    })),
    ...stepInstruction.sampleVideoLinks.map((videoLink, index) => ({
      mediaLinks: [mediaService.createMediaLink(videoLink, caseDetail, stepInstruction, true, index)],
      isVideo: true,
    })),
  ];

  const mediaType = stepInstruction.numPictureRequested ? MediaTypes.IMAGE : MediaTypes.VIDEO;

  const photosLimitReached = MAX_UPLOAD_PHOTO_LIMIT === photos.length;
  const videoLimitReached = MAX_UPLOAD_VIDEO_LIMIT === videos.length;

  return (
    <div className="UploadStep">
      <StepHeader title={stepInstruction.description} stepNumber={stepNumber} totalIndices={totalIndices} />
      {stepInstruction.multipleUnitsStep ? (
        <MultipleUnitsDescription />
      ) : (
        <InstructionGroup instructionsGroup={instructions} showExampleLabel={true} />
      )}
      <div className="UploadStep__numberOfMedia">
        {!!stepInstruction.numPictureRequested &&
          (photosLimitReached ? (
            <div>{t('Upload_photosLimitReached')}</div>
          ) : (
            <div>{t('Upload_addNPhotos', { count: stepInstruction.numPictureRequested })}</div>
          ))}
        {!!stepInstruction.numVideoRequested &&
          (videoLimitReached ? (
            <div>{t('Upload_videosLimitReached')}</div>
          ) : (
            <>
              <div>{t('Upload_addNVideos', { count: stepInstruction.numVideoRequested })}</div>
              <div className="UploadStep__numberOfMedia__comment">{t('Upload_addVideoComment')}</div>
            </>
          ))}
      </div>
      <div className="UploadStep__upload">
        {!!stepInstruction.numPictureRequested && <UploadButton mediaType={MediaTypes.IMAGE} stepNumber={stepNumber} />}
        {!!stepInstruction.numVideoRequested && <UploadButton mediaType={MediaTypes.VIDEO} stepNumber={stepNumber} />}
      </div>
      <UploadedMedia mediaType={mediaType} stepNumber={stepNumber} stepMedia={stepMedia} isMandatory={true} />
    </div>
  );
};

export default UploadStep;
